export const expiredproductdata = [
  {
    id: 1,
    product: "Red Premium Handy",
    productImage: "assets/img/products/expire-product-01.png",
    sku: "PT006",
    manufactureddate: "17 Jan 2023",
    expireddate: "29 Mar 2023",
    Action: "",
  },
  {
    id: 2,
    product: "Iphone 14 Pro",
    productImage: "assets/img/products/expire-product-02.png",
    sku: "PT007",
    manufactureddate: "22 Feb 2023",
    expireddate: "04 Apr 2023",
    Action: "",
  },
  {
    id: 3,
    product: "Black Slim 200",
    productImage: "assets/img/products/expire-product-03.png",
    sku: "PT008",
    manufactureddate: "18 Mar 2023",
    expireddate: "13 May 2023",
    Action: "",
  },
  {
    id: 4,
    product: "Woodcraft Sandal",
    productImage: "assets/img/products/expire-product-04.png",
    sku: "PT009",
    manufactureddate: "29 Mar 2023",
    expireddate: "27 May 2023",
    Action: "",
  },
  {
    id: 5,
    product: "Apple Series 5 Watch",
    productImage: "assets/img/products/stock-img-03.png",
    sku: "PT010",
    manufactureddate: "24 Mar 2023",
    expireddate: "26 May 2023",
    Action: "",
  },
];
