import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  open: false,
};

const authSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state.user = initialState.user;
    },
    setUserModalToggle: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { setUser, resetUser, setUserModalToggle } = authSlice.actions;
export const authReducer = authSlice.reducer;
