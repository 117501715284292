import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: false,
  selectedCompanies: {},
};

const companiesApi = createSlice({
  name: "companiesReducer",
  initialState,
  reducers: {
    setOpenCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setSelectedCompanies: (state, action) => {
      state.selectedCompanies = action.payload;
    },
  },
});

export const { setOpenCompanies, setSelectedCompanies } = companiesApi.actions;
export const companiesReducer = companiesApi.reducer;
