export const subcateorydata = [
	{
    id: 1,
    img: "assets/img/products/appetizers1.jpg",
    category: "Salads",
    parentcategory: "Appetizers",
    categorycode: "APP001",
    description: "Fresh and healthy salads.",
    createdby: "Admin",
  },
  {
    id: 2,
    img: "assets/img/products/appetizers2.jpg",
    category: "Finger Foods",
    parentcategory: "Appetizers",
    categorycode: "APP002",
    description: "Tasty finger foods to start your meal.",
    createdby: "Admin",
  },
  {
    id: 3,
    img: "assets/img/products/maincourses1.jpg",
    category: "Steaks",
    parentcategory: "Main Courses",
    categorycode: "MC001",
    description: "Juicy and flavorful steaks.",
    createdby: "Admin",
  },
  {
    id: 4,
    img: "assets/img/products/maincourses2.jpg",
    category: "Pasta",
    parentcategory: "Main Courses",
    categorycode: "MC002",
    description: "Delicious pasta dishes.",
    createdby: "Admin",
  },
  {
    id: 5,
    img: "assets/img/products/desserts1.jpg",
    category: "Cakes",
    parentcategory: "Desserts",
    categorycode: "DES001",
    description: "Sweet and indulgent cakes.",
    createdby: "Admin",
  },
  {
    id: 6,
    img: "assets/img/products/desserts2.jpg",
    category: "Ice Cream",
    parentcategory: "Desserts",
    categorycode: "DES002",
    description: "Creamy and refreshing ice cream.",
    createdby: "Admin",
  },
  {
    id: 7,
    img: "assets/img/products/beverages1.jpg",
    category: "Soft Drinks",
    parentcategory: "Beverages",
    categorycode: "BEV001",
    description: "Refreshing non-alcoholic beverages.",
    createdby: "Admin",
  },
  {
    id: 8,
    img: "assets/img/products/beverages2.jpg",
    category: "Juices",
    parentcategory: "Beverages",
    categorycode: "BEV002",
    description: "Freshly squeezed juices.",
    createdby: "Admin",
  },
  {
    id: 9,
    img: "assets/img/products/cocktails1.jpg",
    category: "Classic Cocktails",
    parentcategory: "Cocktails",
    categorycode: "CKT001",
    description: "Timeless cocktail recipes.",
    createdby: "Admin",
  },
  {
    id: 10,
    img: "assets/img/products/cocktails2.jpg",
    category: "Signature Cocktails",
    parentcategory: "Cocktails",
    categorycode: "CKT002",
    description: "Unique cocktails crafted by our bartenders.",
    createdby: "Admin",
  },
  {
    id: 11,
    img: "assets/img/products/wines1.jpg",
    category: "Red Wines",
    parentcategory: "Wines",
    categorycode: "WIN001",
    description: "Rich and full-bodied red wines.",
    createdby: "Admin",
  },
  {
    id: 12,
    img: "assets/img/products/wines2.jpg",
    category: "White Wines",
    parentcategory: "Wines",
    categorycode: "WIN002",
    description: "Crisp and refreshing white wines.",
    createdby: "Admin",
  },
  {
    id: 13,
    img: "assets/img/products/rooms1.jpg",
    category: "Single Rooms",
    parentcategory: "Rooms",
    categorycode: "ROM001",
    description: "Cozy rooms for single occupancy.",
    createdby: "Admin",
  },
  {
    id: 14,
    img: "assets/img/products/rooms2.jpg",
    category: "Double Rooms",
    parentcategory: "Rooms",
    categorycode: "ROM002",
    description: "Comfortable rooms for double occupancy.",
    createdby: "Admin",
  },
  {
    id: 15,
    img: "assets/img/products/suites1.jpg",
    category: "Junior Suites",
    parentcategory: "Suites",
    categorycode: "SUT001",
    description: "Elegant suites with additional amenities.",
    createdby: "Admin",
  },
  {
    id: 16,
    img: "assets/img/products/suites2.jpg",
    category: "Executive Suites",
    parentcategory: "Suites",
    categorycode: "SUT002",
    description: "Luxurious suites for executive guests.",
    createdby: "Admin",
  },
  {
    id: 17,
    img: "assets/img/products/eventspaces1.jpg",
    category: "Conference Rooms",
    parentcategory: "Event Spaces",
    categorycode: "EVT001",
    description: "Well-equipped rooms for conferences.",
    createdby: "Admin",
  },
  {
    id: 18,
    img: "assets/img/products/eventspaces2.jpg",
    category: "Ballrooms",
    parentcategory: "Event Spaces",
    categorycode: "EVT002",
    description: "Spacious ballrooms for large events.",
    createdby: "Admin",
  },
  {
    id: 19,
    img: "assets/img/products/spaservices1.jpg",
    category: "Massage Therapy",
    parentcategory: "Spa Services",
    categorycode: "SPA001",
    description: "Relaxing massage therapy sessions.",
    createdby: "Admin",
  },
  {
    id: 20,
    img: "assets/img/products/spaservices2.jpg",
    category: "Facial Treatments",
    parentcategory: "Spa Services",
    categorycode: "SPA002",
    description: "Rejuvenating facial treatments.",
    createdby: "Admin",
  },
  {
    id: 21,
    img: "assets/img/products/gym1.jpg",
    category: "Cardio Equipment",
    parentcategory: "Gym",
    categorycode: "GYM001",
    description: "State-of-the-art cardio machines.",
    createdby: "Admin",
  },
  {
    id: 22,
    img: "assets/img/products/gym2.jpg",
    category: "Strength Training",
    parentcategory: "Gym",
    categorycode: "GYM002",
    description: "Equipment for strength and resistance training.",
    createdby: "Admin",
  },
  {
    id: 23,
    img: "assets/img/products/housekeeping1.jpg",
    category: "Daily Cleaning",
    parentcategory: "Housekeeping",
    categorycode: "HSK001",
    description: "Daily cleaning services for all rooms.",
    createdby: "Admin",
  },
  {
    id: 24,
    img: "assets/img/products/housekeeping2.jpg",
    category: "Laundry Services",
    parentcategory: "Housekeeping",
    categorycode: "HSK002",
    description: "Professional laundry services for guests.",
    createdby: "Admin",
  },
  {
    id: 25,
    img: "assets/img/products/roomservice1.jpg",
    category: "Breakfast",
    parentcategory: "Room Service",
    categorycode: "RMS001",
    description: "Delicious breakfast options delivered to your room.",
    createdby: "Admin",
  },
  {
    id: 26,
    img: "assets/img/products/roomservice2.jpg",
    category: "Late Night Menu",
    parentcategory: "Room Service",
    categorycode: "RMS002",
    description: "Late night food and beverage options.",
    createdby: "Admin",
  },
  {
    id: 27,
    img: "assets/img/products/concierge1.jpg",
    category: "Tour Booking",
    parentcategory: "Concierge",
    categorycode: "CNC001",
    description: "Book tours and activities in the local area.",
    createdby: "Admin",
  },
  {
    id: 28,
    img: "assets/img/products/concierge2.jpg",
    category: "Transportation Services",
    parentcategory: "Concierge",
    categorycode: "CNC002",
    description: "Arrange transportation services for guests.",
    createdby: "Admin",
  },
]