export const StockTransferData = [
  {
    id: "1",
    select: false,
    fromWarehouse: "Lobar Handy",
    toWarehouse: "Selosy",
    noOfProducts: 4,
    quantityTransferred: 14,
    refNumber: "#145445",
    date: "25 Jul 2023",
  },
  {
    id: "2",
    select: false,
    fromWarehouse: "Quaint Warehouse",
    toWarehouse: "Logerro",
    noOfProducts: 21,
    quantityTransferred: 10,
    refNumber: "#135478",
    date: "28 Jul 2023",
  },
  {
    id: "3",
    select: false,
    fromWarehouse: "Traditional Warehouse",
    toWarehouse: "Vesloo",
    noOfProducts: 15,
    quantityTransferred: 14,
    refNumber: "#145124",
    date: "24 Jul 2023",
  },
  {
    id: "4",
    select: false,
    fromWarehouse: "Cool Warehouse",
    toWarehouse: "Crompy",
    noOfProducts: 14,
    quantityTransferred: 74,
    refNumber: "#474541",
    date: "15 Jul 2023",
  },
];
