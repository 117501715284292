import React, { useEffect } from "react";
import Select from "react-select";
import { useAddStoreMutation } from "../../../services/store.api";
import {
  setOpenStore,
  setSelectedStore,
} from "../../redux/slices/store.slice";
import { useForm, Controller } from "react-hook-form";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";

import { useGetAllCountryQuery } from "../../../services/common.api";

const StoreModal = () => {
  const { stores, selectedStore } = useSelector(
    (state) => state?.storeReducer
  );

  const { data: countriesOptions, isLoading: isCountryLoading } =
    useGetAllCountryQuery();

  console.log("selectedstore =>", selectedStore);

  const [addStore, { isLoading }] = useAddStoreMutation();


  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (props) => {
    try {
      const res = await addStore({
        ...props,
        status: props?.status ? 1 : 0,
      }).unwrap();
      if (res?.success == 1) {
        dispatch(setOpenStore(false));
        dispatch(setSelectedStore({}));
        SWLAlert("Success", res?.message, "success");
        reset();
      } else {
        SWLAlert("Error", res?.message, "error");
      }
    } catch (error) {
      SWLAlert("Error", "There was an error adding the store.", "error");
    }
  };

  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenStore(false));
    reset();
  };

  useEffect(() => {
    if (selectedStore?.id) {
      setValue("id", selectedStore?.id);
      setValue("name", selectedStore?.name);
      setValue("phone", selectedStore?.phone);
      setValue("email", selectedStore?.email);
      setValue("address", selectedStore?.address);
      setValue("city", selectedStore?.city);
      setValue("country_id", selectedStore?.country_id);
      setValue("description", selectedStore?.description);
      setValue("status", selectedStore?.status);
    }
  }, [selectedStore?.id, selectedStore]);

  const heading =
    selectedStore?.type != "view"
      ? selectedStore?.id
        ? "Update Store"
        : "Add Store"
      : "View Store";

  return (
    <>
      {/* Add , Edit & View Store */}
      <CustomModal heading={heading} onClose={close} isOpen={stores}>
        {selectedStore?.type != "view" ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">
                    <small className="text-danger">*</small>Name
                  </label>
                  <input
                    type="text"
                    {...register("name", { required: "Name is required" })}
                    className="form-control"
                  />
                  {errors.name && (
                    <p className="text-danger">{errors.name.message}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-6 pe-0">
                <div className="input-blocks">
                  <label className="mb-2">
                    <small className="text-danger">*</small>Phone
                  </label>
                  <input
                    className="form-control form-control-lg group_formcontrol"
                    {...register("phone", {
                      required: "Phone number is required",
                    })}
                    type="text"
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <label className="form-label">
                    <small className="text-danger">*</small>Email
                  </label>
                  <input
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                    className="form-control"
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    {...register("address")}
                    className="form-control"
                  />
                  {errors.address && (
                    <p className="text-danger">{errors.address.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    {...register("city")}
                    className="form-control"
                  />
                  {errors.city && (
                    <p className="text-danger">{errors.city.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <Controller
                    name="country_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="react-select"
                        options={countriesOptions?.data || []}
                        onChange={(e) => field.onChange(e.value)}
                        value={
                          countriesOptions?.data
                            ? countriesOptions?.data.find(
                                (option) => option.value === field.value
                              )
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-danger">{errors.country.message}</p>
                  )}
                </div>
              </div>
              {selectedStore?.id ? (
                <div className="mb-0">
                  <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span className="status-label">Status</span>
                    <input
                      type="checkbox"
                      id="user2"
                      className="check"
                      defaultChecked="true"
                      {...register("status")}
                    />
                    <label htmlFor="user2" className="checktoggle" />
                  </div>
                </div>
              ) : null}
              <div className="col-lg-12">
                <div className="mb-3 input-blocks">
                  <label className="form-label">Descriptions</label>
                  <textarea
                    className="form-control mb-1"
                    {...register("description")}
                  />
                  {errors.description && (
                    <p className="text-danger">{errors.description.message}</p>
                  )}
                  <p>Maximum 60 Characters</p>
                </div>
              </div>
            </div>
            <div className="modal-footer-btn">
              <button
                type="button"
                className="btn btn-cancel me-2"
                onClick={close}
              >
                Cancel
              </button>
              {isLoading ? (
                <button
                  className="btn btn-primary-light"
                  type="button"
                  disabled=""
                >
                  <span
                    className="spinner-border spinner-border-sm align-middle me-1"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-submit"
                  disabled={isLoading}
                >
                  {selectedStore?.id ? 'Update' : 'Save'}
                </button>
              )}
            </div>
          </form>
        ) : (
          <div className="row">
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <h5>Name</h5>
                  <p>{selectedStore?.name}</p>
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <h5>Phone</h5>
                  <p>{selectedStore?.phone}</p>
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <h5>Email</h5>
                  <p>{selectedStore?.email}</p>
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <h5>Address</h5>
                  <p>{selectedStore?.address}</p>
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <h5>City</h5>
                  <p>{selectedStore?.city}</p>
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <h5>Country</h5>
                  <p>{selectedStore?.country_name}</p>
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <h5>Description</h5>
                  <p>{selectedStore?.description}</p>
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <h5>Status</h5>
                  <p>{selectedStore?.status == '1' ? 'Active' : 'Inactive'}</p>
                </div>
              </div>
            </div>
        )}
      </CustomModal>
    </>
  );
};

export default StoreModal;
