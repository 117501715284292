import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addSalesReturnModal: false,
  editSalesReturnModal: false,
};

const salesReturn = createSlice({
  name: "salesReturnReducer",
  initialState,
  reducers: {
    setAddSalesReturnModal: (state, action) => {
      state.addSalesReturnModal = action.payload;
    },
    setEditSalesReturnModal: (state, action) => {
      state.editSalesReturnModal = action.payload;
    },
  },
});

export const { setAddSalesReturnModal, setEditSalesReturnModal } =
  salesReturn.actions;
export const salesReturnReducer = salesReturn.reducer;
