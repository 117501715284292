import React from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setImportStockTransferModal } from "../../redux/slices/stockTransfer.slice";
import { useForm } from "react-hook-form";
import { useGetWarehouseDropdownQuery } from "../../../services/warehouse.api";
import { useProductDropdownQuery } from "../../redux/products.api";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";

const ImportStockTransferModal = () => {
  const dispatch = useDispatch();
  const { importStockTransfersModal } = useSelector(
    (state) => state.stockTransferReducer
  );

  const close = () => {
    dispatch(setImportStockTransferModal(false));
  };

  const { data: warehouseDropdown } = useGetWarehouseDropdownQuery();
  const { data: productDropdown } = useProductDropdownQuery();

  const warehouseDropdownData = warehouseDropdown?.data || [];
  const productDropdownData = productDropdown?.data || [];

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
  });

  const submit = (data) => {
    console.log("---->>", data);

    try {
      const response = "";
      if (response?.data?.success) {
        SWLAlert("Success", response?.data?.message, "success");
      }
    } catch (error) {
      console.error("Add Stock error:", error);
      SWLAlert("Error", response?.data?.message, "error");
    }
  };

  const optionsStore1Store2 = [
    { value: "choose", label: "Choose" },
    { value: "store1", label: "Store 1" },
    { value: "store2", label: "Store 2" },
  ];

  const optionsSentPending = [
    { value: "choose", label: "Choose" },
    { value: "sent", label: "Sent" },
    { value: "pending", label: "Pending" },
  ];
  return (
    <CustomModal
      heading={"Import Transfer"}
      onClose={close}
      isOpen={importStockTransfersModal}
    >
      <form>
        <div className="row">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="input-blocks">
              <label>From</label>
              <Select
                classNamePrefix="react-select"
                options={optionsStore1Store2}
              />
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="input-blocks">
              <label>To</label>
              <Select
                classNamePrefix="react-select"
                options={optionsStore1Store2}
              />
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="input-blocks">
              <label>Satus</label>
              <Select
                classNamePrefix="react-select"
                options={optionsSentPending}
              />
            </div>
          </div>
          <div className="col-lg-12 col-sm-6 col-12">
            <div className="row">
              <div>
                <div className="modal-footer-btn download-file">
                  <Link to="#" className="btn btn-submit">
                    Download Sample File
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks image-upload-down">
              <label> Upload CSV File</label>
              <div className="image-upload download">
                <input type="file" />
                <div className="image-uploads">
                  <ImageWithBasePath
                    src="assets/img/download-img.png"
                    alt="img"
                  />
                  <h4>
                    Drag and drop a <span>file to upload</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-sm-6 col-12">
            <div className="mb-3">
              <label className="form-label">Shipping</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-3 summer-description-box transfer">
            <label className="form-label">Description</label>
            <div id="summernote3"></div>
            <p>Maximum 60 Characters</p>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </CustomModal>
  );
};

export default ImportStockTransferModal;
