export const saleslist = [
  {
    id: "1",
    customerName: "Thomas",
    reference: "SL0101",
    date: "19 Jan 2023",
    status: "Completed",
    grandTotal: "$550",
    paid: "$550",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
  {
    id: "2",
    customerName: "Rose",
    reference: "SL0102",
    date: "26 Jan 2023",
    status: "Completed",
    grandTotal: "$250",
    paid: "$250",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
  {
    id: "3",
    customerName: "Benjamin",
    reference: "SL0103",
    date: "08 Feb 2023",
    status: "Completed",
    grandTotal: "$570",
    paid: "$570",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
  {
    id: "4",
    customerName: "Lilly",
    reference: "SL0104",
    date: "12 Feb 2023",
    status: "Pending",
    grandTotal: "$300",
    paid: "$0.00",
    due: "$300",
    paymentStatus: "Due",
    biller: "Admin",
  },
  {
    id: "5",
    customerName: "Freda",
    reference: "SL0105",
    date: "17 Mar 2023",
    status: "Pending",
    grandTotal: "$700",
    paid: "$0.00",
    due: "$700",
    paymentStatus: "Due",
    biller: "Admin",
  },
  {
    id: "6",
    customerName: "Alwin",
    reference: "SL0106",
    date: "24 Mar 2023",
    status: "Completed",
    grandTotal: "$400",
    paid: "$400",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
  {
    id: "7",
    customerName: "Maybelle",
    reference: "SL0107",
    date: "06 Apr 2023",
    status: "Pending",
    grandTotal: "$120",
    paid: "$0.00",
    due: "$120",
    paymentStatus: "Due",
    biller: "Admin",
  },
  {
    id: "8",
    customerName: "Ellen",
    reference: "SL0108",
    date: "16 Apr 2023",
    status: "Completed",
    grandTotal: "$830",
    paid: "$830",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
  {
    id: "9",
    customerName: "Kaitlin",
    reference: "SL0109",
    date: "04 May 2023",
    status: "Pending",
    grandTotal: "$800",
    paid: "$0.00",
    due: "$800",
    paymentStatus: "Due",
    biller: "Admin",
  },
  {
    id: "10",
    customerName: "Grace",
    reference: "SL0110",
    date: "29 May 2023",
    status: "Completed",
    grandTotal: "$460",
    paid: "$460",
    due: "$0.00",
    paymentStatus: "Paid",
    biller: "Admin",
  },
];
