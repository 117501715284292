import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addPurchaseReturnModal: false,
  editPurchaseReturnModal: false,
};

const purchaseReturn = createSlice({
  name: "purchaseReturnReducer",
  initialState,
  reducers: {
    setAddPurchaseReturnModal: (state, action) => {
      state.addPurchaseReturnModal = action.payload;
    },
    setEditPurchaseReturnModal: (state, action) => {
      state.editPurchaseReturnModal = action.payload;
    },
  },
});

export const { setAddPurchaseReturnModal, setEditPurchaseReturnModal } =
  purchaseReturn.actions;
export const purchaseReturnReducer = purchaseReturn.reducer;
