import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  stores: false,
  selectedUnit:{}
};

const storesApi = createSlice({
  name: 'storeReducer',
  initialState,
  reducers: {
    setOpenStore: (state, action) => {
      state.stores = action.payload;
    },
    setSelectedStore: (state, action) => {
      state.selectedStore = action.payload;
    },

  },
});

export const {
setOpenStore,
setSelectedStore
} = storesApi.actions;
export const storeReducer = storesApi.reducer;
