export const attendenceemployeedata = [
  {
    id: 1,
    date: "01 Jan 2023",
    clockin: "09:15 AM",
    clockout: "08:55 PM",
    production: "9h 00m",
    break: "1h 13m",
    overtime: "00h 50m",
    progress: [78, 55, 15],
    status: "Present",
    totalhours: "09h 50m",
  },
  {
    id: 2,
    date: "02 Jan 2023",
    clockin: "09:07 AM",
    clockout: "08:40 PM",
    production: "9h 10m",
    break: "1h 07m",
    overtime: "01h 13m",
    progress: [100, 0, 0],
    status: "Present",
    totalhours: "10h 23m",
  },
  {
    id: 3,
    date: "03 Jan 2023",
    clockin: "09:04 AM",
    clockout: "08:52 PM",
    production: "8h 47m",
    break: "1h 04m",
    overtime: "01h 07m",
    progress: [90, 0, 10],
    status: "Present",
    totalhours: "10h 04m",
  },
  {
    id: 4,
    date: "04 Jan 2023",
    clockin: "-",
    clockout: "-",
    production: "-",
    break: "-",
    overtime: "-",
    progress: [78, 55, 15],
    status: "Absent",
    totalhours: "-",
  },
  {
    id: 5,
    date: "06 Jan 2023",
    clockin: "09:10 AM",
    clockout: "08:48 PM",
    production: "8h 38m",
    break: "0h 58m",
    overtime: "01h 08m",
    progress: [78, 55, 15],
    status: "Present",
    totalhours: "09h 46m",
  },
  {
    id: 6,
    date: "07 Jan 2023",
    clockin: "09:03 AM",
    clockout: "08:57 PM",
    production: "8h 50m",
    break: "1h 26m",
    overtime: "0h 43m",
    progress: [78, 55, 15],
    status: "Present",
    totalhours: "08h 33m",
  },
  {
    id: 7,
    date: "04 Jan 2023",
    clockin: "-",
    clockout: "-",
    production: "-",
    break: "-",
    overtime: "-",
    progress: [78, 55, 15],
    status: "Holiday",
    totalhours: "-",
  },
];
