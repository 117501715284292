import React from "react";

import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setNotesStockAdjModal } from "../../redux/slices/stockAdjustment.slice";
import { useForm } from "react-hook-form";

const NotesStockadjModal = () => {
  const dispatch = useDispatch();
  const { viewNotesModal, stockAdjModalData } = useSelector(
    (state) => state.stockAdjustmentReducer
  );

  const close = () => {
    dispatch(setNotesStockAdjModal(false));
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
  });

  return (
    <div>
      {/* View Notes */}
      <CustomModal
        heading={"Notes"}
        onClose={close}
        isOpen={viewNotesModal}
        noValidate
        id="notes-stockadj-modal"
      >
        <p>{stockAdjModalData?.reason || ""}</p>
      </CustomModal>

      {/* /View Notes */}
    </div>
  );
};

export default NotesStockadjModal;
