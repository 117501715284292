import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addStockAdjustmentsModal: false,
  editStockAdjustmentsModal: false,
  viewNotesModal: false,
  stockAdjModalData: {},
  stockAdjustments: false,
  selectedStockAdjustment: {},
};

const stockAdjustmentsApi = createSlice({
  name: "stockAdjustmentReducer",
  initialState,
  reducers: {
    setOpenStockAdjustment: (state, action) => {
      state.stockAdjustments = action.payload;
    },
    setSelectedStockAdjustment: (state, action) => {
      state.selectedStockAdjustment = action.payload;
    },
    setAddStockAdjModal: (state, action) => {
      state.addStockAdjustmentsModal = action.payload;
    },
    setEditStockAdjModal: (state, action) => {
      state.editStockAdjustmentsModal = action.payload;
    },
    setNotesStockAdjModal: (state, action) => {
      state.viewNotesModal = action.payload;
    },
    setStockAdjModalData: (state, action) => {
      state.stockAdjModalData = action.payload;
    },
  },
});

export const {
  setOpenStockAdjustment,
  setSelectedStockAdjustment,
  setAddStockAdjModal,
  setEditStockAdjModal,
  setNotesStockAdjModal,
  setStockAdjModalData,
} = stockAdjustmentsApi.actions;
export const stockAdjustmentReducer = stockAdjustmentsApi.reducer;
