import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import LoadingSpinner from "../../../InitialPage/Sidebar/LoadingSpinner";
import { setUserModalToggle } from "../../../core/redux/user.slice";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useUpdateUserPasswordMutation } from "../../../services/user.api";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });
  const { user, open } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const [updatePassword] = useUpdateUserPasswordMutation();
  const submit = (props) => {
    props.id = user?.id;
    delete props?.repeat_password;
    updatePassword({ ...props }).then((res) => {
      if (res?.data?.success) {
        dispatch(setUserModalToggle(false));
        SWLAlert("Success", res?.data?.message, "success");
      } else {
        SWLAlert("Error", res?.data?.message, "error");
      }
    });
  };
  const close = () => {
    dispatch(setUserModalToggle(false));
  };

  return (
    <>
      <CustomModal heading={"Reset Password"} onClose={close} isOpen={open}>
        <form
          noValidate
          onSubmit={handleSubmit(submit)}
          id="reset-password-form"
        >
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="input-blocks">
                <label className="form-label">Old password</label>
                <input
                  type="password"
                  name="oldPassword"
                  className="form-control"
                  {...register("oldPassword")}
                  onChange={(e) => {
                    setValue("oldPassword", e?.target?.value);
                  }}
                />
                {errors.oldPassword && (
                  <p className="text-danger">{errors.oldPassword.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="input-blocks">
                <label className="form-label">New Password</label>
                <input
                  type="password"
                  name="newPassword"
                  className="form-control"
                  {...register("newPassword")}
                  onChange={(e) => {
                    setValue("newPassword", e?.target?.value);
                  }}
                />
                {errors.newPassword && (
                  <p className="text-danger">{errors.newPassword.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="input-blocks">
                <label className="form-label">Repeat Password</label>
                <input
                  type="password"
                  name="repeat_password"
                  className="form-control"
                  {...register("repeat_password")}
                  onChange={(e) => {
                    setValue("repeat_password", e?.target?.value);
                  }}
                />
                {errors.repeat_password && (
                  <p className="text-danger">
                    {errors.repeat_password.message}
                  </p>
                )}
              </div>
            </div>

            <div className="col-12">
              <button type="submit" className="btn btn-submit me-2">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default ChangePassword;

const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  newPassword: yup.string().required("New Password is required"),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Repeat Password is required"),
});
