// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../core/redux/baseQuery";

// Define a service using a base URL and expected endpoints
export const permissionsApi = createApi({
  reducerPath: "permissionsApi",
  baseQuery: baseQuery,
  tagTypes: ["permissions"],
  endpoints: (builder) => ({
    getPermissionById: builder.query({
      query: (id) => ({
        url: `permission/${id}`,
        method: "GET",
      }),
      providesTags: ["permissions"],
    }),
    addPermission: builder.mutation({
      query: (newPermission) => ({
        url: "permission",
        method: "POST",
        body: newPermission,
      }),
      invalidatesTags: ["permissions"],
    }), 
  }),
});

export const { useGetPermissionByIdQuery, useAddPermissionMutation } = permissionsApi;

export default permissionsApi;
