import { Link } from 'react-router-dom'
import React, { useEffect } from "react";
import { useAddRoleMutation } from "../../../services/role.api";
import {
  setOpenRole,
  setSelectedRole,
} from "../../redux/slices/role.slice";
import { useForm, Controller } from "react-hook-form";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";

const AddRole = () => {
    const { roles, selectedRole } = useSelector(
        (state) => state?.roleReducer
      );
    
      const [addRole, { isLoading }] = useAddRoleMutation();
    
    
      const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setValue,
      } = useForm();
    
      const onSubmit = async (props) => {
        try {
          const res = await addRole({
            ...props,
            status: props?.status ? 1 : 0,
          }).unwrap();
          if (res?.success == 1) {
            dispatch(setOpenRole(false));
            dispatch(setSelectedRole({}));
            SWLAlert("Success", res?.message, "success");
            reset();
          } else {
            SWLAlert("Error", res?.message, "error");
          }
        } catch (error) {
          SWLAlert("Error", "There was an error adding the Role.", "error");
        }
      };
    
      const dispatch = useDispatch();
      const close = () => {
        dispatch(setOpenRole(false));
        reset();
      };
    
      useEffect(() => {
        if (selectedRole?.id) {
          setValue("id", selectedRole?.id);
          setValue("title", selectedRole?.title);
          setValue("status", selectedRole?.status);
        }
      }, [selectedRole?.id, selectedRole]);
    
      const heading =selectedRole?.id
            ? "Update Role"
            : "Add Role";

    return (
        <div>
            {/* Add Role */}
            <CustomModal heading={heading} onClose={close} isOpen={roles}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">
                    <small className="text-danger">*</small>Title
                  </label>
                  <input
                    type="text"
                    {...register("title", { required: "Title is required" })}
                    className="form-control"
                  />
                  {errors.title && (
                    <p className="text-danger">{errors.name.message}</p>
                  )}
                </div>
              </div>
              {selectedRole?.id ? (
                <div className="mb-0">
                  <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span className="status-label">Status</span>
                    <input
                      type="checkbox"
                      id="user2"
                      className="check"
                      defaultChecked="true"
                      {...register("status")}
                    />
                    <label htmlFor="user2" className="checktoggle" />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="modal-footer-btn">
              <button
                type="button"
                className="btn btn-cancel me-2"
                onClick={close}
              >
                Cancel
              </button>
              {isLoading ? (
                <button
                  className="btn btn-primary-light"
                  type="button"
                  disabled=""
                >
                  <span
                    className="spinner-border spinner-border-sm align-middle me-1"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-submit"
                  disabled={isLoading}
                >
                  {selectedRole?.id ? 'Update' : 'Save'}
                </button>
              )}
            </div>
          </form>
      </CustomModal>
           {/*  <div className="modal fade" id="add-units">
                <div className="modal-dialog modal-dialog-centered custom-modal-two">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Create Role</h4>
                                    </div>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <form>
                                        <div className="mb-0">
                                            <label className="form-label">Role Name</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="modal-footer-btn">
                                            <button
                                                type="button"
                                                className="btn btn-cancel me-2"
                                                data-bs-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                            <Link to="#" className="btn btn-submit">
                                                Create Role
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* /Add Role */}
        </div>
    )
}

export default AddRole
