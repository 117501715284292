import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  category: false,
  selectedCategory:{},
  selectedProduct:{},


};

const productSlice = createSlice({
  name: 'productsReducer',
  initialState,
  reducers: {
    setOpenCategory: (state, action) => {
      state.category = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },

    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },

  },
});

export const {
setOpenCategory,
setSelectedCategory,
setSelectedProduct
} = productSlice.actions;
export const productsReducer = productSlice.reducer;
