import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  salesModal: {
    modalType: "",
    modal: false,
    modalData: {},
  },
};

const sales = createSlice({
  name: "salesReducer",
  initialState,
  reducers: {
    setSalesModal: (state, action) => {
      const { modalType, modal, ...modalData } = action.payload;
      state.salesModal = {
        modalType,
        modal,
        modalData,
      };
    },
  },
});

export const { setSalesModal } = sales.actions;
export const salesReducer = sales.reducer;
