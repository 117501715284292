import { createSlice } from '@reduxjs/toolkit';

const initialState= {
};

const commonsApi = createSlice({
  name: 'commonReducer',
  initialState,
  reducers: {}
});

export const commonReducer = commonsApi.reducer;
