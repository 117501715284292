import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: false,
  selectedUnit: {},
};

const usersApi = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setOpenUser: (state, action) => {
      state.users = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
  },
});

export const { setOpenUser, setSelectedUser } = usersApi.actions;
export const userReducer = usersApi.reducer;
