import axios from 'axios';
import { auth, base_url } from '../../../env';
import { endpoints } from '../../endpoints';


export const Login=async(data)=>{
    const options = {
        url: `${base_url}${auth}/${endpoints.login}`, 
        method: 'POST', 
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json'
        },
        data:JSON.stringify(data),
      };
      const response=await axios.request(options);
      return response;
}