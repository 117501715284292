import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentModal: {
    modalType: "",
    modal: false,
    modalData: {},
  },
};

const payment = createSlice({
  name: "paymentReducer",
  initialState,
  reducers: {
    setPaymentModal: (state, action) => {
      const { modalType, modal, ...modalData } = action.payload;
      state.paymentModal = {
        modalType,
        modal,
        modalData,
      };
    },
  },
});

export const { setPaymentModal } = payment.actions;
export const paymentReducer = payment.reducer;
