// src/utils/dateUtils.js
import moment from 'moment';

export const formatDate = (date, type = 'date', dateType = 'show') => {
  try {
    if(dateType == 'show'){
      const format = type == 'dateTime' ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY';
      return moment(date).format(format);
    }else{
      const format = type == 'dateTime' ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD';
      return moment(date).format(format);
    }
  } catch (error) {
    console.error('Invalid date or format:', error);
    return null;
  }
};
