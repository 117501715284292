export const dashboarrecentproductddata = [
  {
    id: "2",
    img: "assets/img/products/wine-bottle.png",
    product: "Red Wine - Merlot",
    price: "$50.00",
  },
  {
    id: "3",
    img: "assets/img/products/steak.png",
    product: "Prime Ribeye Steak",
    price: "$80.00",
  },
  {
    id: "4",
    img: "assets/img/products/coffee-beans.png",
    product: "Espresso Coffee",
    price: "$5.00",
  },
  {
    id: "5",
    img: "assets/img/products/cocktail-glass.png",
    product: "Martini Glasses",
    price: "$10.00",
  }
];
