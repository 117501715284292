export const purchasesreturn = [
  {
    id: "2",
    img: "assets/img/products/wine-bottle.png",
    date: "20 Jun 2023",
    supplier: "Wine Wholesalers Inc.",
    reference: "DR001",
    status: "Received",
    grandTotal: "$300.00",
    paid: "$0.00",
    due: "$300.00",
    paymentStatus: "Unpaid",
  },
  {
    id: "3",
    img: "assets/img/products/coffee-beans.png",
    date: "25 Jun 2023",
    supplier: "Coffee Roasters Inc.",
    reference: "DR004",
    status: "Pending",
    grandTotal: "$200.00",
    paid: "$0.00",
    due: "$200.00",
    paymentStatus: "Unpaid",
  },
  {
    id: "4",
    img: "assets/img/products/beer-mug.png",
    date: "10 Jul 2023",
    supplier: "Barware Distributors",
    reference: "DR005",
    status: "Received",
    grandTotal: "$100.00",
    paid: "$100.00",
    due: "$0.00",
    paymentStatus: "Fully Paid",
  },
  {
    id: "5",
    img: "assets/img/products/steak.png",
    date: "05 Jul 2023",
    supplier: "Meat Suppliers Co.",
    reference: "FD001",
    status: "Received",
    grandTotal: "$150.00",
    paid: "$150.00",
    due: "$0.00",
    paymentStatus: "Fully Paid",
  },
  {
    id: "6",
    img: "assets/img/products/pasta.png",
    date: "18 Jul 2023",
    supplier: "Kitchen Essentials Ltd.",
    reference: "FD004",
    status: "Pending",
    grandTotal: "$80.00",
    paid: "$0.00",
    due: "$80.00",
    paymentStatus: "Unpaid",
  },
  {
    id: "7",
    img: "assets/img/products/whiskey-bottle.png",
    date: "22 Jul 2023",
    supplier: "Liquor Wholesalers Ltd.",
    reference: "DR006",
    status: "Received",
    grandTotal: "$250.00",
    paid: "$200.00",
    due: "$50.00",
    paymentStatus: "Partially Paid",
  },
  {
    id: "8",
    img: "assets/img/products/ice-cream.png",
    date: "30 Jul 2023",
    supplier: "Frozen Foods Inc.",
    reference: "FD005",
    status: "Received",
    grandTotal: "$50.00",
    paid: "$50.00",
    due: "$0.00",
    paymentStatus: "Fully Paid",
  },
  {
    id: "9",
    img: "assets/img/products/gin.png",
    date: "02 Aug 2023",
    supplier: "Beverage Distributors Ltd.",
    reference: "DR002",
    status: "Pending",
    grandTotal: "$100.00",
    paid: "$0.00",
    due: "$100.00",
    paymentStatus: "Unpaid",
  },
  {
    id: "10",
    img: "assets/img/products/macarons.png",
    date: "05 Aug 2023",
    supplier: "Dessert Suppliers Co.",
    reference: "FD006",
    status: "Received",
    grandTotal: "$30.00",
    paid: "$0.00",
    due: "$30.00",
    paymentStatus: "Unpaid",
  }
];
