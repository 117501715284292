import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import AddUnit from "../../core/modals/inventory/addunit";
import EditUnit from "../../core/modals/inventory/editunit";
import Swal from "sweetalert2";
import {
  Calendar,
  ChevronUp,
  Filter,
  PlusCircle,
  RotateCcw,
  Sliders,
  StopCircle,
  Zap,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { DatePicker } from "antd";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setToogleHeader } from "../../core/redux/action";
import {
  useDeleteUnitMutation,
  useUnitsQuery,
} from "../../core/redux/unitsapi";
import LoadingSpinner from "../../InitialPage/Sidebar/LoadingSpinner";
import Spinner from "../uiinterface/spinner";
import GrowLoader from "../uiinterface/GrowLoader";
import { setOpenUnit, setSelectedUnit } from "../../core/redux/units.slice";
import { FormProvider, useForm } from "react-hook-form";
import { Loader } from "react-feather";

export const Units = () => {
  const dataSource = useSelector((state) => state.unit_data);
  const methods = useForm({
    mode: "all",
    defaultValues: {
      id: null,
      title: "",
      short_name: "",
      status: 1,
    },
  });
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const {
    data: unitsList,
    isLoading,
    isFetching,
  } = useUnitsQuery({}, { refetchOnMountOrArgChange: false });
  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const unit = [
    { value: "Choose Unit", label: "Choose Unit" },
    { value: "Piece", label: "Piece" },
    { value: "Kilogram", label: "Kilogram" },
    { value: "Gram", label: "Gram" },
  ];
  const status = [
    { value: "choose Status", label: "Choose Status" },
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const columns = [
    {
      title: "Unit",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Short Name",
      dataIndex: "short_name",
      sorter: (a, b) => a.short_name.length - b.short_name.length,
    },

    {
      title: "NO of Products",
      dataIndex: "no_of_product",
      sorter: (a, b) => a.no_of_product.length - b.no_of_product.length,
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      render: (text) => (
        <span>
          <Link>{text ? text?.split("T")[0] : "-"}</Link>
        </span>
      ),
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => {
        return (
          <span
            className={
              text == 1 ? "badge badge-linesuccess" : "badge-linedangered"
            }
          >
            <Link to="#"> {text == 1 ? "Active" : "InActive"}</Link>
          </span>
        );
      },
      sorter: (a, b) => a.status.length - b.status.length,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, dt) => {
        return (
          <div className="action-table-data">
            <div className="edit-delete-action">
              <Link
                onClick={() => {
                  methods?.reset({ ...dt });
                  dispatch(setOpenUnit(true));
                  dispatch(setSelectedUnit(dt));
                }}
                className="me-2 p-2"
              >
                <i data-feather="edit" className="feather-edit"></i>
              </Link>
              <Link className="confirm-text p-2" to="#" onClick={() => {}}>
                <i
                  data-feather="trash-2"
                  className="feather-trash-2"
                  onClick={() => showConfirmationAlert(dt?.id)}
                ></i>
              </Link>
            </div>
          </div>
        );
      },
    },
  ];
  const MySwal = withReactContent(Swal);
  const [deleteUnit, { isLoading: deleting }] = useDeleteUnitMutation();
  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUnit(id).then(() => {
          MySwal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <>
      {isLoading || isFetching || deleting ? <LoadingSpinner /> : null}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Units</h4>
                <h6>Manage your units</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div
              className="page-btn"
              onClick={() => {
                dispatch(setSelectedUnit({}));
                methods?.reset({
                  title: "",
                  short_name: "",
                  status: 1,
                  id: null,
                });
                dispatch(setOpenUnit(true));
              }}
            >
              <a className="btn btn-added">
                <PlusCircle className="me-2" />
                Add New Unit
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${
                      isFilterVisible ? "setclose" : ""
                    }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={oldandlatestvalue}
                    placeholder="Newest"
                  />
                </div>
              </div>
              {/* /Filter */}

              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Zap className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={unit}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Calendar className="info-img" />
                        <div className="input-groupicon">
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            type="date"
                            className="filterdatepicker"
                            dateFormat="dd-MM-yyyy"
                            placeholder="Choose Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={status}
                          placeholder="Choose Brand"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <button className="btn btn-filters ms-auto">
                          <i data-feather="search" className="feather-search" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={unitsList?.data}
                  rowSelect={(row) => {}}
                />
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
        <FormProvider {...methods}>
          <AddUnit values={methods?.getValues()} />
          <EditUnit />
        </FormProvider>
      </div>
    </>
  );
};
