import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Login } from "../../../Networking/services/Auth";
import { all_routes } from "../../../Router/all_routes";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { set_storage_item } from "../../../utils/Method";
import { useDispatch } from "react-redux";
import { setUser } from "../../../core/redux/user.slice";
import { SWLAlert } from "../../uiinterface/SWLAlert";
const SigninThree = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signInHandler, setSignInHandler] = useState({
    isPasswordVisible: false,
    email: '',
    password: '',
    isError: false,
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setSignInHandler((prevState) => ({
      ...prevState,
      [name]: value,
      isError: false,
    }));
  };

  const togglePasswordVisibility = () => {
    setSignInHandler((prevState) => ({
      ...prevState,
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  const handleSignIn = () => {
    const { email, password } = signInHandler;

    if (!email || !password) {
      setSignInHandler((prevState) => ({
        ...prevState,
        isError: true,
      }));
      return;
    }

    Login({ email, password }).then((resp) => {
      if(resp?.data?.success == 1){
        set_storage_item('token', resp?.data?.token);
        dispatch(setUser(resp?.data?.data));
        if (email === 'admin@admin.com') {
          navigate(all_routes?.superadmindashboard);
        } else {
          navigate(all_routes?.dashboard);
        }
      }else{
        SWLAlert("Error", resp?.data?.message, "error");
      }
    }).catch((error) => {
      // Handle errors appropriately
      console.error("Login failed:", error);
      setSignInHandler((prevState) => ({
        ...prevState,
        isError: true,
      }));
    });
  };

  return (
    <div className="main-wrappers">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container">
            <div className="login-content user-login">
              <div className="login-logo">
                <ImageWithBasePath src="assets/img/logo.png" alt="Logo" />
              </div>
              <form>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                    <h4>
                      Access the Bar Management System panel using your email and passcode.
                    </h4>
                  </div>
                  <div className="form-login">
                    <label className="form-label">Email Address</label>
                    <div className="form-addons">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        style={{ borderColor: signInHandler.isError && !signInHandler.email && 'red' }}
                        value={signInHandler.email}
                        onChange={handleInputChange}
                      />
                      <ImageWithBasePath src="assets/img/icons/mail.svg" alt="Email Icon" />
                    </div>
                  </div>
                  <div className="form-login">
                    <label>Password</label>
                    <div className="pass-group">
                      <input
                        type={signInHandler.isPasswordVisible ? 'text' : 'password'}
                        name="password"
                        className="pass-input form-control"
                        style={{ borderColor: signInHandler.isError && !signInHandler.password && 'red' }}
                        value={signInHandler.password}
                        onChange={handleInputChange}
                      />
                      <span
                        className={`fas toggle-password ${signInHandler.isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={togglePasswordVisibility}
                      ></span>
                    </div>
                  </div>
                  <div className="form-login authentication-check">
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <Link className="forgot-link" to={all_routes?.forgotPassword}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-login">
                    <button type="button" className="btn btn-login" onClick={handleSignIn}>
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
              <p>Copyright © 2024 Bar Management System. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninThree;
// const SigninThree = () => {
//   const navigate = useNavigate();

//   const route = all_routes;
//   const [signInhandler, setSignInHandler] = useState({
//     isPasswordVisible: false,
//     email: null,
//     password: null,
//     isError: false,
//   });
//   const OnInputhandle = (val, key) => {
//     setSignInHandler({ ...signInhandler, [key]: val, isError: true });
//   };
//   const dispatch = useDispatch();

//   const OnSignIn = () => {
//     if (signInhandler?.email == null || signInhandler?.password == null) {
//       setSignInHandler({ ...signInhandler, isError: true });
//     } else {
//       Login({ ...signInhandler }).then((resp) => {
//         set_storage_item("token", resp?.data?.token);
//         dispatch(setUser(resp?.data?.data));
//         if(signInhandler?.email == 'admin@admin.com'){
//           navigate(all_routes?.superadmindashboard);
//         }else{
//           navigate(all_routes?.dashboard);
//         }
//       });
//     }
//   };

//   return (
//     <div className="main-wrappers">
//       <div className="account-content">
//         <div className="login-wrapper login-new">
//           <div className="container">
//             <div className="login-content user-login">
//               <div className="login-logo">
//                 <ImageWithBasePath src="assets/img/logo.png" alt="img" />
//               </div>
//               <form>
//                 <div className="login-userset">
//                   <div className="login-userheading">
//                     <h3>Sign In</h3>
//                     <h4>
//                       Access the Bar Management System panel using your email
//                       and passcode.
//                     </h4>
//                   </div>
//                   <div className="form-login">
//                     <label className="form-label">Email Address</label>
//                     <div className="form-addons">
//                       <input
//                         type="text"
//                         className="form-control"
//                         style={{
//                           borderColor:
//                             signInhandler?.isError &&
//                             signInhandler?.email == null &&
//                             "red",
//                         }}
//                         onChange={(val) =>
//                           OnInputhandle(val.target.value, "email")
//                         }
//                       />
//                       <ImageWithBasePath
//                         src="assets/img/icons/mail.svg"
//                         alt="img"
//                       />
//                     </div>
//                   </div>
//                   <div className="form-login">
//                     <label>Password</label>
//                     <div className="pass-group">
//                       <input
//                         style={{
//                           borderColor:
//                             signInhandler?.isError &&
//                             signInhandler?.password == null &&
//                             "red",
//                         }}
//                         type={isPasswordVisible ? "text" : "password"}
//                         className="pass-input form-control"
//                         onChange={(val) =>
//                           OnInputhandle(val.target.value, "password")
//                         }
//                       />
//                       <span
//                       className={`fas toggle-password ${
//                         isPasswordVisible ? "fa-eye" : "fa-eye-slash"
//                       }`}
//                       onClick={togglePasswordVisibility}
//                       ></span>
//                     </div>
//                   </div>
//                   <div className="form-login authentication-check">
//                     <div className="row">
//                       <div className="col-6">
//                         <div className="custom-control custom-checkbox">
//                           <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
//                             <input type="checkbox" />
//                             <span className="checkmarks" />
//                             Remember me
//                           </label>
//                         </div>
//                       </div>
//                       <div className="col-6 text-end">
//                         <Link className="forgot-link" to={route.forgotPassword}>
//                           Forgot Password?
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-login" onClick={OnSignIn}>
//                     <p className="btn btn-login">Sign In</p>
//                   </div>
//                 </div>
//               </form>
//             </div>
//             <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
//               <p>Copyright © 2024 Bar Management System. All rights reserved</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SigninThree;
