import { baseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

const addressEndPoints = {
  address: (id) => `/address/by-customer-id/${id}`,
  createAddress: "/address/create",
  uppdateAddress: (id) => `/address/update/${id}`,
  getAddressById: (id) => `/address/${id}`,
};

const unitsApi = createApi({
  baseQuery: baseQuery,
  reducerPath: "unitsApi/reducer",
  tagTypes: ["units"],
  endpoints: (build) => ({
    units: build.query({
      query: (id) => {
        return {
          url: "/unit",
          method: "GET",
        };
      },
      providesTags: ["units"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [
          ...(data?.data )||[],
        ]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.title,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),
    addUnit: build.mutation({
      query: (body) => {
        return {
          url: "/unit",
          method: body?.id?"PUT":"POST",
          body
        };
      },
      invalidatesTags: ["units"],
    }),
    deleteUnit: build.mutation({
      query: (id) => {
        return {
          url: `/unit/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["units"],

    }),
    // unit/dropdown/list
    unitDropdown: build.query({
      query: (body) => {
        return {
          url: "unit/dropdown/list",
          method: "GET",
        };
      },
      providesTags:["units"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.title,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {useUnitDropdownQuery, useUnitsQuery,useAddUnitMutation ,useDeleteUnitMutation} = unitsApi;


export default unitsApi