export const salersretrunsdata = [
	{
    id: 2,
    img: "assets/img/products/champagne-bottle.png",
    productname: "Champagne - Brut",
    date: "20 Jun 2023",
    customer: "Alexander Smith",
    status: "Received",
    grandtotal: "$100.00",
    paid: "$100.00",
    due: "$0.00",
    paymentstatus: "Fully Paid",
  },
  {
    id: 3,
    img: "assets/img/products/cocktail-glass.png",
    productname: "Martini Glasses",
    date: "25 Jun 2023",
    customer: "Sophia Martinez",
    status: "Pending",
    grandtotal: "$80.00",
    paid: "$0.00",
    due: "$80.00",
    paymentstatus: "Unpaid",
  },
  {
    id: 4,
    img: "assets/img/products/wine-bottle.png",
    productname: "Red Wine - Merlot",
    date: "10 Jul 2023",
    customer: "Michael Brown",
    status: "Received",
    grandtotal: "$150.00",
    paid: "$50.00",
    due: "$100.00",
    paymentstatus: "Partially Paid",
  },
  {
    id: 5,
    img: "assets/img/products/steak.png",
    productname: "Prime Ribeye Steak",
    date: "05 Jul 2023",
    customer: "Olivia Green",
    status: "Received",
    grandtotal: "$80.00",
    paid: "$80.00",
    due: "$0.00",
    paymentstatus: "Fully Paid",
  },
  {
    id: 6,
    img: "assets/img/products/espresso.png",
    productname: "Espresso Coffee",
    date: "18 Jul 2023",
    customer: "William Taylor",
    status: "Pending",
    grandtotal: "$30.00",
    paid: "$0.00",
    due: "$30.00",
    paymentstatus: "Unpaid",
  },
  {
    id: 7,
    img: "assets/img/products/cocktail-shaker.png",
    productname: "Cocktail Shakers",
    date: "22 Jul 2023",
    customer: "Sophie Anderson",
    status: "Received",
    grandtotal: "$50.00",
    paid: "$50.00",
    due: "$0.00",
    paymentstatus: "Fully Paid",
  },
  {
    id: 8,
    img: "assets/img/products/cheese.png",
    productname: "Cheddar Cheese",
    date: "30 Jul 2023",
    customer: "David Clark",
    status: "Received",
    grandtotal: "$20.00",
    paid: "$20.00",
    due: "$0.00",
    paymentstatus: "Fully Paid",
  },
  {
    id: 9,
    img: "assets/img/products/pasta.png",
    productname: "Pasta - Penne",
    date: "02 Aug 2023",
    customer: "Lucas Baker",
    status: "Pending",
    grandtotal: "$15.00",
    paid: "$0.00",
    due: "$15.00",
    paymentstatus: "Unpaid",
  },
  {
    id: 10,
    img: "assets/img/products/ice-cream.png",
    productname: "Ice Cream - Vanilla",
    date: "05 Aug 2023",
    customer: "Sophia Lee",
    status: "Received",
    grandtotal: "$10.00",
    paid: "$0.00",
    due: "$10.00",
    paymentstatus: "Unpaid",
  },
]