import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { all_routes } from "../../Router/all_routes";
import { DatePicker } from "antd";
import AddBrand from "../../core/modals/addbrand";
import {
  ArrowLeft,
  Calendar,
  ChevronDown,
  ChevronUp,
  Info,
  LifeBuoy,
  // List,
  PlusCircle,
  // Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  useAddProductMutation,
  useCategoryDropdownQuery,
  useSingleProductQuery,
  useStoreDropdownQuery,
  useWarehouseDropdownQuery,
} from "../../core/redux/products.api";
import CustomDropdown from "../uiinterface/Dropdown";
import { FormProvider, useForm } from "react-hook-form";
import { setOpenCategory } from "../../core/redux/products.slice";
import AddUnit from "../../core/modals/inventory/addunit";
import AddCategoryList from "../../core/modals/inventory/addcategorylist";
import { setOpenUnit } from "../../core/redux/units.slice";
import { useUnitDropdownQuery } from "../../core/redux/unitsapi";
import { SWLAlert } from "../uiinterface/SWLAlert";
import LoadingSpinner from "../../InitialPage/Sidebar/LoadingSpinner";
import { skipToken } from "@reduxjs/toolkit/query";
import { get_storage_item } from "../../utils/Method";

export const taxtype = [
  { value: 1, label: "Exclusive" },
  { value: 2, label: "Sales Tax" },
];
export const discounttype = [
  { value: 1, label: "Percentage" },
  { value: 2, label: "Cash" },
];


const AddProduct = () => {

  const methods = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      productTitle: "",
      store_id: null,
      ware_house_id: null,
      category_id: null,
      unit_id: null,
      manufactured_date: new Date(),
      expiry_on: new Date(),
      qty: 0,
      qty_alert: 0,
      price: 0,
      tax_type: 0,
      discount_type: 0,
      discount: 0,
      description: "",
    },
  });

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because getMonth() returns zero-based index
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const { data: storeOpyions } = useStoreDropdownQuery();
  const { data: categoryOptions } = useCategoryDropdownQuery();
  const { data: unitsOptions } = useUnitDropdownQuery();

  const navigate = useNavigate();
  const location = useLocation();
  const { setValue, handleSubmit, register, getValues, reset, watch, formState: { errors } } =
    methods || {};

  const route = all_routes;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const { selectedProduct } = useSelector((state) => state.productsReducer);

  const {
    data: singleData,
    isFetching,
    isLoading,
  } = useSingleProductQuery(
    get_storage_item("productId") || selectedProduct?.id
      ? get_storage_item("productId") || selectedProduct?.id
      : skipToken
  );
  const isValid = location?.pathname?.includes("edit") && singleData?.data;

  useEffect(() => {
    if (singleData?.data && location?.pathname?.includes("edit")) {
      setValue("category_id", singleData?.data?.category_id);
      setValue("id", singleData?.data?.id);
      setValue("description", singleData?.data?.description);
      setValue("discount", singleData?.data?.discount);
      setValue("discount_type", singleData?.data?.discount_type);

      setValue("title", singleData?.data?.title);
      setValue("store_id", singleData?.data?.store_id);
      setValue("unit_id", singleData?.data?.unit_id);
      setValue("qty_alert", singleData?.data?.qty_alert);
    } else {
      setValue("discount_type", 0);
    }
  }, [singleData?.data, selectedProduct, location?.pathname?.includes("edit")]);

  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const [addProducts, { isLoading: isSubmitting }] = useAddProductMutation();

  const submit = (props) => {
    addProducts({
      ...props,
      status: props?.status ? 1 : 0,
    }).then((res) => {
      if (res?.data?.success) {
        SWLAlert("Success", res?.data?.message, "success");
        navigate("/product-list");
      } else {
        SWLAlert("Error", res?.data?.message, "error");
      }
    });
  };
  return (
    <>
      {isLoading || isFetching ? (
        <LoadingSpinner />
      ) : (
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>{isValid ? `Product` : "New Product"}</h4>
                  <h6>
                    {isValid
                      ? `Update Product: ${singleData?.data?.title}`
                      : "Create new product"}
                  </h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <div className="page-btn">
                    <Link to={route.productlist} className="btn btn-secondary">
                      <ArrowLeft className="me-2" />
                      Back to Product
                    </Link>
                  </div>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderCollapseTooltip}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Collapse"
                      id="collapse-header"
                      className={data ? "active" : ""}
                      onClick={() => {
                        dispatch(setToogleHeader(!data));
                      }}
                    >
                      <ChevronUp className="feather-chevron-up" />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
            </div>
            {/* /add */}
            <form noValidate onSubmit={handleSubmit(submit)}>
              <div className="card">
                <div className="card-body add-product pb-0">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="mb-3 add-product">
                        <label className="form-label">
                          <small className="text-danger">*</small> Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("title", { required: "Name is required" })}
                          onChange={(e) => {
                            setValue("title", e?.target?.value);
                          }}
                          defaultValue={watch("title")}
                        />
                        {errors.title && (
                          <p className="text-danger">{errors.title.message}</p>
                        )}

                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="mb-3 add-product">
                      <label className="form-label">
                          <small className="text-danger">*</small> Store
                        </label>
                        <CustomDropdown
                          {...register("store_id", { required: "Store is required" })}
                          options={storeOpyions?.data || []}
                          value={watch("store_id")}
                          // label={"Store"}
                          onChange={(val) => {
                            setValue("store_id", val?.value);
                          }}
                        />
                        {errors.store_id && (
                          <p className="text-danger">{errors.store_id.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="mb-3 add-product">
                        <div className="add-newplus">
                          <label className="form-label">
                          <small className="text-danger">*</small> Category</label>
                          <Link
                            onClick={() => {
                              dispatch(setOpenCategory(true));
                            }}
                          >
                            <PlusCircle className="plus-down-add" />
                            <span>Add New</span>
                          </Link>
                        </div>
                        <CustomDropdown
                          {...register("category_id")}
                          options={categoryOptions?.data || []}
                          value={watch("category_id")}
                          // label={"Category"}
                          onChange={(val) => {
                            setValue("category_id", val?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="mb-3 add-product">
                        <div className="add-newplus">
                          <label className="form-label">
                          <small className="text-danger">*</small> Unit</label>
                          <Link
                            onClick={() => {
                              dispatch(setOpenUnit(true));
                              reset({
                                title: "",
                                short_name: "",
                                id: null,
                              });
                            }}
                          >
                            <PlusCircle className="plus-down-add" />
                            <span>Add New</span>
                          </Link>
                        </div>
                        <CustomDropdown
                          {...register("unit_id")}
                          options={unitsOptions?.data || []}
                          value={watch("unit_id")}
                          // label={"Unit"}
                          onChange={(val) => {
                            setValue("unit_id", val?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks add-product">
                        <CustomDropdown
                          label={"Discount Type"}
                          options={discounttype}
                          value={watch("discount_type")}
                          placeholder="Select Option"
                          {...register("discount_type")}
                          onChange={(val) => {
                            setValue("discount_type", val?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks add-product">
                        <label>Discount Value</label>
                        <input
                          type="text"
                          placeholder="Choose"
                          {...register("discount")}
                          defaultValue={watch("discount")}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks add-product">
                        <label>Quantity Alert</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("qty_alert")}
                          defaultValue={watch("qty_alert")}
                        />
                      </div>
                    </div>
                    {/* Editor */}
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box transfer mb-3">
                        <label>Description</label>
                        <textarea
                          className="form-control h-100"
                          rows={5}
                          {...register("description")}
                          defaultValue={watch("description")}
                        />
                        <p className="mt-1">Maximum 60 Characters</p>
                      </div>
                    </div>
                    {/* /Editor */}
                  </div>

                </div>
              </div>
              <div className="col-lg-12">
                <div className="btn-addproduct mb-4">
                  <button type="button" className="btn btn-cancel me-2">
                    Cancel
                  </button>
                  {
                    isSubmitting ? (<button
                      className="btn btn-primary-light"
                      type="button"
                      disabled=""
                    >
                      <span
                        className="spinner-border spinner-border-sm align-middle me-1"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>) :
                      (<button type="submit" className="btn btn-submit">
                        {isValid ? "Update" : "Save"}
                      </button>)
                  }
                </div>
              </div>
            </form>
            {/* /add */}
          </div>
          <FormProvider {...methods}>
            <AddUnit />
            <AddCategoryList />
            <AddBrand />
          </FormProvider>
        </div>
      )}
    </>
  );
};

export default AddProduct;
