export const purchaseslist = [
  {
    id: "1",
    supplierName: "Wine Wholesalers Inc.",
    reference: "DR001",
    date: "15 Jun 2023",
    status: "Received",
    grandTotal: "$2000.00",
    paid: "$1500.00",
    due: "$500.00",
    createdBy: "Michael Brown",
  },
  {
    id: "2",
    supplierName: "Beverage Distributors Ltd.",
    reference: "DR002",
    date: "20 Jun 2023",
    status: "Received",
    grandTotal: "$1000.00",
    paid: "$1000.00",
    due: "$0.00",
    createdBy: "Sophia Martinez",
  },
  {
    id: "3",
    supplierName: "Meat Suppliers Co.",
    reference: "FD001",
    date: "25 Jun 2023",
    status: "Pending",
    grandTotal: "$800.00",
    paid: "$0.00",
    due: "$800.00",
    createdBy: "Olivia Green",
  },
  {
    id: "4",
    supplierName: "Fresh Produce Ltd.",
    reference: "FD002",
    date: "10 Jul 2023",
    status: "Received",
    grandTotal: "$1200.00",
    paid: "$1000.00",
    due: "$200.00",
    createdBy: "William Taylor",
  },
  {
    id: "5",
    supplierName: "Dairy Farms Inc.",
    reference: "DR003",
    date: "05 Jul 2023",
    status: "Received",
    grandTotal: "$500.00",
    paid: "$500.00",
    due: "$0.00",
    createdBy: "Sophie Anderson",
  },
  {
    id: "6",
    supplierName: "Bakery Supplies Ltd.",
    reference: "FD003",
    date: "18 Jul 2023",
    status: "Pending",
    grandTotal: "$300.00",
    paid: "$0.00",
    due: "$300.00",
    createdBy: "David Clark",
  },
  {
    id: "7",
    supplierName: "Coffee Roasters Inc.",
    reference: "DR004",
    date: "22 Jul 2023",
    status: "Received",
    grandTotal: "$400.00",
    paid: "$400.00",
    due: "$0.00",
    createdBy: "Lucas Baker",
  },
  {
    id: "8",
    supplierName: "Kitchen Essentials Ltd.",
    reference: "FD004",
    date: "30 Jul 2023",
    status: "Received",
    grandTotal: "$600.00",
    paid: "$600.00",
    due: "$0.00",
    createdBy: "Sophia Lee",
  },
  {
    id: "9",
    supplierName: "Barware Distributors",
    reference: "DR005",
    date: "02 Aug 2023",
    status: "Pending",
    grandTotal: "$200.00",
    paid: "$0.00",
    due: "$200.00",
    createdBy: "Emily Johnson",
  },
  {
    id: "10",
    supplierName: "Frozen Foods Inc.",
    reference: "FD005",
    date: "05 Aug 2023",
    status: "Received",
    grandTotal: "$150.00",
    paid: "$150.00",
    due: "$0.00",
    createdBy: "Daniel White",
  },
];
