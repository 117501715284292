import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  suppliers: false,
  selectedUnit:{}
};

const suppliersApi = createSlice({
  name: 'supplierReducer',
  initialState,
  reducers: {
    setOpenSupplier: (state, action) => {
      state.suppliers = action.payload;
    },
    setSelectedSupplier: (state, action) => {
      state.selectedSupplier = action.payload;
    },

  },
});

export const {
setOpenSupplier,
setSelectedSupplier
} = suppliersApi.actions;
export const supplierReducer = suppliersApi.reducer;
