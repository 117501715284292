import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  table: false,
  selectedTable: {},
};

const tableApi = createSlice({
  name: "tableReducer",
  initialState,
  reducers: {
    setOpenTable: (state, action) => {
      state.table = action.payload;
    },
    setSelectedTable: (state, action) => {
      state.selectedTable = action.payload;
    },
  },
});

export const { setOpenTable, setSelectedTable } = tableApi.actions;
export const tableReducer = tableApi.reducer;
