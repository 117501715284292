import React, { useEffect } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserProfileMutation } from "../../services/user.api";
import { SWLAlert } from "../uiinterface/SWLAlert";
import LoadingSpinner from "../../InitialPage/Sidebar/LoadingSpinner";
import { setUser, setUserModalToggle } from "../../core/redux/user.slice";
import CustomModal from "../uiinterface/CustomModal";
import ChangePassword from "./resetpassword/changepassword";

const Profile = () => {
  const { register, handleSubmit, watch, setValue } = useForm();
  const { user, open } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const [isPasswordVisible, setPasswordVisible] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setPasswordVisible((prevState) => !prevState);
  // };

  const [updateProfile, { isLoading }] = useUpdateUserProfileMutation();

  const submit = (props) => {
    if (props) {
      updateProfile({ ...props }).then((res) => {
        if (res?.data?.success) {
          // dispatch(setUser({}))
          SWLAlert("Success", res?.data?.message, "success");
        } else {
          SWLAlert("Error", res?.data?.message, "error");
        }
      });
    }
  };

  useEffect(() => {
    if (!!user) {
      setValue("id", user?.id);
      setValue("name", user?.name);
      setValue("email", user?.email);
      setValue("phone", user?.phone);
      setValue("gender", user?.gender);
    }
  }, [user, setValue]);

  return (
    <>
      <div className="page-wrapper">
        {isLoading ? <LoadingSpinner /> : null}
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Profile</h4>
              <h6>User Profile</h6>
            </div>
          </div>
          {/* /product list */}

          <form
            noValidate
            onSubmit={handleSubmit(submit)}
            id="profile-update-form"
          >
            <div className="card">
              <div className="card-body">
                <div className="profile-set">
                  <div className="profile-head"></div>
                  <div className="profile-top">
                    <div className="profile-content">
                      <div className="profile-contentimg">
                        <ImageWithBasePath
                          src="assets/img/customer/customer5.jpg"
                          alt="img"
                          id="blah"
                        />
                        <div className="profileupload">
                          <input type="file" id="imgInp" />
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/icons/edit-set.svg"
                              alt="img"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="profile-contentname">
                        <h2>{user?.name || ""}</h2>
                        <h4>Updates Your Photo and Personal Details.</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-blocks">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        {...register("first_name")}
                        defaultValue={watch("first_name") || ""}
                        onChange={(e) => {
                          setValue("first_name", e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-blocks">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        {...register("last_name")}
                        defaultValue={watch("last_name") || ""}
                        onChange={(e) => {
                          setValue("last_name", e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-blocks">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        {...register("email")}
                        defaultValue={watch("email") || ""}
                        onChange={(e) => {
                          setValue("email", e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="input-blocks">
                      <label className="form-label">Phone</label>
                      <input
                        type="text"
                        {...register("phone")}
                        defaultValue={watch("phone") || ""}
                        onChange={(e) => {
                          setValue("phone", e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue="William Castilo"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
              </div> */}
                  <div className="col-12">
                    <button type="submit" className="btn btn-submit me-2">
                      Submit
                    </button>
                    {/* <button type="" className="btn btn-cancel">
                    Cancel
                    </button> */}
                    <button
                      type="button"
                      className="btn btn-submit me-2"
                      onClick={() => dispatch(setUserModalToggle(true))}
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* /product list */}
        </div>
        <ChangePassword />
      </div>
    </>
  );
};

export default Profile;
