import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addPurchaseModal: false,
  editPurchaseModal: false,
};

const purchase = createSlice({
  name: "purchaseReducer",
  initialState,
  reducers: {
    setAddPurchaseModal: (state, action) => {
      state.addPurchaseModal = action.payload;
    },
    setEditPurchaseModal: (state, action) => {
      state.editPurchaseModal = action.payload;
    },
  },
});

export const { setAddPurchaseModal, setEditPurchaseModal } = purchase.actions;
export const purchaseReducer = purchase.reducer;
