export const attandanceadmindata = [
  {
    id: 1,
    img: "assets/img/users/user-01.jpg",
    empname: "Mitchum Daniel\n     Administrator",
    empid: "POS001",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "09:15 AM",
    production: "9h 22m",
    overtime: "1h 13m",
    totalhours: "10h 15m",
    approval: "Approved",
  },
  {
    id: 2,
    img: "assets/img/users/user-02.jpg",

    empname: "Janet Hembre\n     Administrative",
    empid: "POS003",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "09:07 AM",
    production: "9h 55m",
    overtime: "0h 50m",
    totalhours: "10h 50m",
    approval: "Approved",
  },
  {
    id: 3,
    img: "assets/img/users/user-03.jpg",

    empname: "Russell Belle\n     Technician",
    empid: "POS004",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "08:58 AM",
    production: "9h 10m",
    overtime: "1h 05m",
    totalhours: "10h 05m",
    approval: "Approved",
  },
  {
    id: 4,
    img: "assets/img/users/user-04.jpg",

    empname: "Robert Grossman\n     Administrator",
    empid: "POS005",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "09:02 AM",
    production: "9h 20m",
    overtime: "1h 03m",
    totalhours: "10h 03m",
    approval: "Approved",
  },
  {
    id: 5,
    img: "assets/img/users/user-05.jpg",

    empname: "Edward Muniz\n     Secretary",
    empid: "POS006",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "09:08 AM",
    production: "9h 30m",
    overtime: "1h 55m",
    totalhours: "10h 55m",
    approval: "Approved",
  },
  {
    id: 6,
    img: "assets/img/users/user-06.jpg",
    empname: "Susan Moore\n     Tech Lead",

    empid: "POS007",
    date: "15 Jan 2023",
    shift: "Regular",
    clockin: "09:13 AM",
    production: "9h 40m",
    overtime: "1h 52m",
    totalhours: "10h 52m",
    approval: "Approved",
  },
];
