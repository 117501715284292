import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  warehouses: false,
  selectedUnit:{}
};

const warehousesApi = createSlice({
  name: 'warehouseReducer',
  initialState,
  reducers: {
    setOpenWarehouse: (state, action) => {
      state.warehouses = action.payload;
    },
    setSelectedWarehouse: (state, action) => {
      state.selectedWarehouse = action.payload;
    },

  },
});

export const {
setOpenWarehouse,
setSelectedWarehouse
} = warehousesApi.actions;
export const warehouseReducer = warehousesApi.reducer;
