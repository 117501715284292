import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  units: false,
  selectedUnit:{}
};

const unitsApi = createSlice({
  name: 'unitReducer',
  initialState,
  reducers: {
    setOpenUnit: (state, action) => {
      state.units = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload;
    },

  },
});

export const {
setOpenUnit,
setSelectedUnit
} = unitsApi.actions;
export const unitReducer = unitsApi.reducer;
