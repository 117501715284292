import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import ThemeSettings from "../InitialPage/themeSettings";
import { get_storage_item } from "../utils/Method";
import { all_routes } from "./all_routes";
import { pagesRoute, posRoutes, publicRoutes, superAdminRoutes } from "./router.link";
import SuperAdminSidebar from "../InitialPage/Sidebar/SuperAdminSidebar";
import PrivateRoute from './private_route';

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const navigate = useNavigate();
  const token = get_storage_item("token");
  const parseJwt = (token) => {
    try {
      // Split the token into its parts
      const base64Url = token.split('.')[1];
      // Replace Base64Url characters with Base64 characters
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      // Decode Base64
      const jsonPayload = window.atob(base64);
      // Parse JSON
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error('Invalid token format:', e);
      return {};
    }
  }
  /* if (!token) {
    navigate(all_routes?.signin);
  }else{
    const userData = parseJwt(token);
    // console.log('userData =>', userData);
    if(userData?.token?.email == 'admin@admin.com'){
      navigate(all_routes?.superadmindashboard);
    } else {
      navigate(all_routes?.dashboard);
    }
  } */

  useEffect(() => {

    
    if (!token) {
      navigate(all_routes?.signin);
    }else{
      const userData = parseJwt(token);
      // console.log('userData =>', userData);
      /* if(userData?.token?.email == 'admin@admin.com'){
        navigate(all_routes?.superadmindashboard);
      } else {
        navigate(all_routes?.dashboard);
      } */
    }
  }, [navigate, token]);

   
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      {/* <Loader /> */}
      <Header />
      <Sidebar />
      <Outlet />
      {/* <ThemeSettings /> */}
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Loader /> */}
      {/* <ThemeSettings /> */}
    </div>
  );

  const Pospages = () => (
    <div>
      <Header />
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  );
  const SuperAdminPages = () => (
    <div>
       <Header />
       <SuperAdminSidebar />
      <Outlet />
      {/* <Loader /> */}
      {/* <ThemeSettings /> */}
    </div>
  );

  return (
    <div>
      <Routes>
        <Route path="/pos" element={<Pospages />}>
          {posRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
        
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>

        <Route path={"/"} element={ 
              <Authpages />
      }>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
        
        <Route path="/" element={<SuperAdminPages />}>
          {superAdminRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
        
      </Routes>
    </div>
  );
};
export default AllRoutes;
