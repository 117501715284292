import React, { useEffect, useState } from "react";
import { Calendar, PlusCircle } from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import Select from "react-select";
import { DatePicker } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ImageWithBasePath from "../../img/imagewithbasebath";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { setAddSalesReturnModal } from "../../redux/slices/salesreturn.slice";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux/store";
import { setPaymentModal } from "../../redux/slices/payment.slice";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import {
  useAddPaymentMutation,
  useDeletePaymentMutation,
  useGetPaymentListQuery,
  useGetPaymentQuery,
  useUpdatePaymentMutation,
} from "../../../services/payment.api";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import salesApi from "../../../services/sales.api";
import { formatDate } from "../../../utils/datePipe";
import LoadingSpinner from "../../../InitialPage/Sidebar/LoadingSpinner";

const CreatePayment = () => {
  const { paymentModal } = useSelector((state) => state.paymentReducer);

  const isAddPayment = paymentModal?.modalType === "Add";
  const isEditPayment = paymentModal?.modalType === "Edit";
  const isViewPayment = paymentModal?.modalType === "View";
  const modalOpen = paymentModal?.modal;
  const modalData = paymentModal?.modalData;

  const { data: singlePayment, isLoading: isFetchingList } =
    useGetPaymentListQuery(modalData?.sale_id, {
      skip: !modalData?.sale_id,
    });

  const close = () => {
    dispatch(setPaymentModal({ modal: false }));
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(addPaymentSchema),
  });

  const [addPayment, { isLoading }] = useAddPaymentMutation();
  const [updatePayment, { isLoading: isUpdating }] = useUpdatePaymentMutation();

  const submit = async (data) => {
    let response;
    try {
      if (isEditPayment) {
        const editObj = {
          ...modalData,
          amount: data.amount,
          payment_method: data.payment_method,
        };

        response = await updatePayment(editObj);
      } else {
        const obj = {
          sale_id: modalData?.sale_id,
          ...data,
        };

        response = await addPayment(obj);
        reset();
      }
      if (response?.data?.success) {
        close();
        SWLAlert(response.data.message, "success");
      } else {
        SWLAlert(
          "Error",
          response?.data?.message || "Something went wrong",
          "error"
        );
      }
    } catch (error) {
      console.error(" Payment error:", error);
      SWLAlert(
        "Error",
        error.message || "An unexpected error occurred",
        "error"
      );
    }
  };

  const [deletePayment] = useDeletePaymentMutation();
  const MySwal = withReactContent(Swal);
  const handlePaymentDelete = async (id) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      await deletePayment(id);
      MySwal.fire({
        title: "Deleted!",
        text: "Your record has been deleted.",
        icon: "success",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    } else {
      MySwal.close();
    }
  };

  useEffect(() => {
    if (modalData) {
      setValue("payment_date", modalData?.payment_date);
      // setValue("payment_method", modalData?.payment_method);
    }
  }, [modalData]);

  const paymenttype = [
    { value: "1", label: "Cash" },
    { value: "2", label: "Online" },
    { value: "3", label: "Card" },
  ];

  const modalHeading = isAddPayment
    ? "Add Payment"
    : isEditPayment
    ? "Edit Payment"
    : "Show Payment";

  return (
    <>
      {isLoading || isFetchingList || isUpdating ? <LoadingSpinner /> : null}
      <CustomModal
        heading={modalHeading}
        size="xl"
        onClose={close}
        isOpen={modalOpen}
      >
        <div className="card">
          {!isViewPayment && (
            <form onSubmit={handleSubmit(submit)}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="input-blocks">
                    <label>
                      <small className="text-danger">*</small>Date
                    </label>
                    <div className="input-groupicon calender-input">
                      <Controller
                        name="payment_date"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Calendar className="info-img" />
                            <DatePicker
                              value={field?.value ? moment(field.value) : null}
                              onChange={(date, dateString) =>
                                field.onChange(dateString || null)
                              }
                              name={field.name}
                              className="filterdatepicker"
                              placeholder="Choose Date"
                              disabled={isEditPayment ? true : false}
                            />
                          </>
                        )}
                      />
                    </div>
                    {errors.payment_date && (
                      <p className="text-danger mt-1">
                        {errors.payment_date.message}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="col-lg-6 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>Reference</label>
                    <input type="text" className="form-control" />
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      <small className="text-danger">*</small>Received Amount
                    </label>
                    <div className="input-groupicon calender-input">
                      <i data-feather="dollar-sign" className="info-img" />
                      <input
                        type="text"
                        name="amount"
                        defaultValue={modalData?.amount || ""}
                        {...register("amount")}
                      />
                      {errors.amount && (
                        <p className="text-danger mt-1">
                          {errors.amount.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>Paying Amount</label>
                    <div className="input-groupicon calender-input">
                      <i data-feather="dollar-sign" className="info-img" />
                      <input type="text" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 col-12">
                  <div className="input-blocks">
                    <label>
                      <small className="text-danger">*</small>Payment type
                    </label>
                    <Controller
                      name="payment_method"
                      control={control}
                      rules={{ required: "customer is required" }}
                      render={({ field }) => (
                        <Select
                          classNamePrefix="react-select"
                          options={paymenttype}
                          onChange={(e) => field.onChange(e.value)}
                          value={paymenttype.find((option) =>
                            isEditPayment
                              ? option.value === modalData?.payment_method
                              : option.value === field.value
                          )}
                        />
                      )}
                    />
                  </div>
                  {errors.payment_method && (
                    <p className="text-danger">
                      {errors.payment_method.message}
                    </p>
                  )}
                </div>
                <div className="col-lg-12">
                  <div className="input-blocks">
                    <label>Description</label>
                    <textarea className="form-control" defaultValue={""} />
                    <p>Maximum 60 Characters</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="modal-footer-btn">
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    onClick={close}
                  >
                    Cancel
                  </button>
                  {isLoading || isUpdating ? (
                    <button
                      className="btn btn-primary-light"
                      type="button"
                      disabled=""
                    >
                      <span
                        className="spinner-border spinner-border-sm align-middle me-1"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-submit">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}

          {/* VIEW PAyment Modal */}
          {isViewPayment && (
            <div className="row">
              <div className="col-lg-12">
                <div className="modal-body-table total-orders">
                  <div className="table-responsive">
                    <table className="table  datanew">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Reference</th>
                          <th>Amount</th>
                          <th>Paid By</th>
                          <th className="no-sort">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singlePayment?.data?.map((record) => (
                          <tr>
                            <td key={record.payment_id}>
                              {formatDate(record.payment_date) || ""}
                            </td>
                            <td>{""}</td>
                            <td>{record.amount || ""}</td>
                            <td>Cash</td>
                            <td className="action-table-data">
                              <div className="edit-delete-action">
                                {/* <Link className="me-3 p-2" to="#">
                                  <i
                                    data-feather="printer"
                                    className="feather-rotate-ccw"
                                  />
                                </Link> */}
                                <Link
                                  className="me-3 p-2"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#editpayment"
                                  onClick={() =>
                                    dispatch(
                                      setPaymentModal({
                                        modalType: "Edit",
                                        modal: true,
                                        ...record,
                                      })
                                    )
                                  }
                                >
                                  <i
                                    data-feather="edit"
                                    className="feather-edit"
                                  />
                                </Link>
                                <Link
                                  className="confirm-text p-2"
                                  onClick={() =>
                                    handlePaymentDelete(record.payment_id)
                                  }
                                >
                                  <i
                                    data-feather="trash-2"
                                    className="feather-trash-2"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default CreatePayment;

const addPaymentSchema = yup.object().shape({
  payment_date: yup.string().required("date is required"),
  amount: yup
    .number()
    .typeError("amount must be a number")
    .required("amount is required"),
  payment_method: yup.string().required("payment method is required"),
});
