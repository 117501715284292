import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useGetAllRoleQuery, useGetDropdownRoleQuery } from "../../../services/role.api";
import { useAddUserMutation } from "../../../services/user.api";
import {
  setOpenUser,
  setSelectedUser,
} from "../../redux/slices/user.slice";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useAddCompaniesMutation } from "../../../services/compaines.api";
import { setOpenCompanies, setSelectedCompanies } from "../../redux/slices/companies.slice";
import { useGetAllCountryQuery } from "../../../services/common.api";

const AddCompanies = () => {

  const { companies, selectedCompanies } = useSelector(
    (state) => state?.companiesReducer
  );

  console.log('selectedCompanies =>', selectedCompanies);

  const { data: countriesOptions, isLoading: isCountryLoading } =
  useGetAllCountryQuery();

  const [addCompany, { isLoading }] = useAddCompaniesMutation();

  // const { data: rolesOptions } = useGetDropdownRoleQuery();

  const genderOptions = [
    { value: "", label: "Choose" },
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (props) => {
    try {
      const res = await addCompany({
        ...props,
        status: props?.status ? 1 : 0,
      }).unwrap();
      if (res?.success == 1) {
        dispatch(setOpenCompanies(false));
        dispatch(setSelectedCompanies({}));
        SWLAlert("Success", res?.message, "success");
        reset();
      } else {
        SWLAlert("Error", res?.message, "error");
      }
    } catch (error) {
      SWLAlert("Error", "There was an error adding the Company.", "error");
    }
  };

  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenCompanies(false));
    reset();
  };

  useEffect(() => {
    if (selectedCompanies?.company_id) {
      setValue("id", selectedCompanies?.company_id);
      setValue("name", selectedCompanies?.name);
      setValue("email", selectedCompanies?.email);
      setValue("phone", selectedCompanies?.phone);
      setValue("address", selectedCompanies?.address);
      setValue("state", selectedCompanies?.state);
      setValue("city", selectedCompanies?.city);
      setValue("postal_code", selectedCompanies?.postal_code);
      setValue("country", selectedCompanies?.country);
      setValue("status", selectedCompanies?.status);
    }
  }, [selectedCompanies?.company_id, selectedCompanies]);

  const heading = selectedCompanies?.company_id
    ? "Update Company"
    : "Add Company";



  const [showPassword, setShowPassword] = useState(false);


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const modal_ref = useRef(null);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const handleToggleConfirmPassword = () => {
    setConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const OnHandleInputChange = (value, field) => {
    const currentValues = getValues();
    setValue(field, value);
    setValue("formData", { ...currentValues, [field]: value });
  };


  return (
    <div>
      {/* Add Companies */}
      <CustomModal heading={heading} onClose={close} isOpen={companies}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <label> <small className="text-danger">*</small>Name</label>
                <input
                  type="text"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  className="form-control"
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Phone</label>
                <input
                  type="text"
                  {...register("phone", {
                    required: "Phone is required",
                  })}
                  className="form-control"
                />
                {errors.phone && (
                  <p className="text-danger">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="input-blocks">
                <label><small className="text-danger">*</small>Email</label>
                <input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email is not valid",
                    },
                  })}
                  type="email"
                  className="form-control"
                />
                {errors.email && <p className="text-danger">{errors.email.message}</p>}
              </div>
            </div>
            <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    {...register("address")}
                    className="form-control"
                  />
                  {errors.address && (
                    <p className="text-danger">{errors.address.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    {...register("state")}
                    className="form-control"
                  />
                  {errors.state && (
                    <p className="text-danger">{errors.state.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    {...register("city")}
                    className="form-control"
                  />
                  {errors.city && (
                    <p className="text-danger">{errors.city.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">Zipcode</label>
                  <input
                    type="text"
                    {...register("postal_code")}
                    className="form-control"
                  />
                  {errors.postal_code && (
                    <p className="text-danger">{errors.postal_code.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-6 pe-0">
                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="react-select"
                        options={countriesOptions?.data || []}
                        onChange={(e) => field.onChange(e.value)}
                        value={
                          countriesOptions?.data
                            ? countriesOptions?.data.find(
                              (option) => option.value == field.value
                            )
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.country && (
                    <p className="text-danger">{errors.country.message}</p>
                  )}
                </div>
              </div>

            {!selectedCompanies?.company_id ? (
               <div className="col-lg-6">
               <div className="input-blocks">
                 <label>Password</label>
                 <div className="pass-group">
                   <input
                     {...register("password", {
                       required: "Password is required",
                     })}
                     type={showPassword ? "text" : "password"}
                     className="pass-input"
                     placeholder="Enter your password"
                   /* onChange={(val) =>
                     OnHandleInputChange(
                       val.target.value,
                       "password"
                     )
                   } */
                   />
                   {errors.password && (
                     <p className="text-danger">{errors.password.message}</p>
                   )}
                   <span
                     className={`fas toggle-password ${showPassword ? "fa-eye" : "fa-eye-slash"
                       }`}
                     onClick={handleTogglePassword}
                   />
                 </div>
               </div>
             </div>
              ) : null}
            {!selectedCompanies?.company_id ? (
                <div className="col-lg-6">
                <div className="input-blocks">
                  <label>Confirm Password</label>
                  <div className="pass-group">
                    <input
                      {...register("confirm_password", {
                        required: "Confirm Password is required",
                      })}
                      type={showConfirmPassword ? "text" : "password"}
                      className="pass-input"
                      placeholder="Enter your password"
                    /* onChange={(val) =>
                      OnHandleInputChange(
                        val.target.value,
                        "confirm_password"
                      )
                    } */
                    />
                    {errors.confirm_password && (
                      <p className="text-danger">{errors.confirm_password.message}</p>
                    )}
                    <span
                      className={`fas toggle-password ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      onClick={handleToggleConfirmPassword}
                    />
                  </div>
                </div>
              </div>
              ) : null}
            {selectedCompanies?.company_id ? (
                <div className="mb-0">
                  <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span className="status-label">Status</span>
                    <input
                      type="checkbox"
                      id="user2"
                      className="check"
                      defaultChecked="true"
                      {...register("status")}
                    />
                    <label htmlFor="user2" className="checktoggle" />
                  </div>
                </div>
              ) : null}
          </div>
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                className="btn btn-primary-light"
                type="button"
                disabled=""
              >
                <span
                  className="spinner-border spinner-border-sm align-middle me-1"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-submit"
                disabled={isLoading}
              >
                {selectedCompanies?.company_id ? 'Update' : 'Save'}
              </button>
            )}
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default AddCompanies;
