import React from "react";

import * as Icon from "react-feather";

export const SuperAdminSidebarData = [
  {
    label: "Main",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "Dashboard",
        link: "/superadmin/dashboard",
        icon: <Icon.Grid />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
 
  {
    label: "Companies Section",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Companies Section",
    submenuItems: [
      {
        label: "Companies",
        link: "/superadmin/companies",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
      },
    ],
  },
];
