import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addStockTransfersModal: false,
  editStockTransfersModal: false,
  editStockTransfersModalData: {},
  importStockTransfersModal: false,
  stockTransfers: false,
  selectedStockTransfer: {},
};

const stockTransfersApi = createSlice({
  name: "stockTransferReducer",
  initialState,
  reducers: {
    setOpenStockTransfer: (state, action) => {
      state.stockTransfers = action.payload;
    },
    setSelectedStockTransfer: (state, action) => {
      state.selectedStockTransfer = action.payload;
    },
    setAddStockTransferModal: (state, action) => {
      state.addStockTransfersModal = action.payload;
    },
    setEditStockTransferModal: (state, action) => {
      state.editStockTransfersModal = action.payload;
    },
    setImportStockTransferModal: (state, action) => {
      state.importStockTransfersModal = action.payload;
    },
    setEditStockTransferModalData: (state, action) => {
      state.editStockTransfersModalData = action.payload;
    },
  },
});

export const {
  setOpenStockTransfer,
  setSelectedStockTransfer,
  setAddStockTransferModal,
  setEditStockTransferModal,
  setImportStockTransferModal,
  setEditStockTransferModalData,
} = stockTransfersApi.actions;
export const stockTransferReducer = stockTransfersApi.reducer;
