import React, { useEffect, useState } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import { useGetWarehouseDropdownQuery } from "../../../services/warehouse.api";
import { useProductDropdownQuery } from "../../redux/products.api";
import { useForm, Controller } from "react-hook-form";
import { useAddStockMutation } from "../../../services/stock.api";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setAddStockModal, setOpenStock } from "../../redux/slices/stock.slice";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useGetUserDropdownQuery } from "../../../services/user.api";
import { Calendar } from "feather-icons-react/build/IconComponents";
import { DatePicker } from "antd";
import { formatDate } from "../../../utils/datePipe";

const AddStockModal = () => {
  // const { addModalToggle } = useSelector((state) => state.stockReducer);
  const { stocks, selectedStock } = useSelector((state) => state?.stockReducer);

  console.log("selectedStock =>", selectedStock);

  const dispatch = useDispatch();
  const { data: warehouseDropdown } = useGetWarehouseDropdownQuery();
  const { data: productDropdown } = useProductDropdownQuery();
  const { data: userDropdown } = useGetUserDropdownQuery();

  const [addStock, { isLoading: isSubmitting }] = useAddStockMutation();

  const warehouseDropdownData = warehouseDropdown?.data || [];
  const productDropdownData = productDropdown?.data || [];
  const userDropdownData = userDropdown?.data || [];

  const [manufacturedDate, setManufacturedDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const close = () => {
    dispatch(setOpenStock(false));
    reset();
  };

  useEffect(() => {
    if (selectedStock?.stock_id) {
      setValue("id", selectedStock?.stock_id);
      setValue("product_id", selectedStock?.product_id);
      setValue("ware_house_id", selectedStock?.warehouse_id);
      setValue("responsible_user_id", selectedStock?.responsible_user_id);
      setValue("price", selectedStock?.price);
      setValue("quantity", selectedStock?.quantity);
      setValue("manufactured_date", selectedStock?.manufactured_date);
      setValue("expiry_on", selectedStock?.expiry_on);
    }
  }, [selectedStock?.stock_id, selectedStock]);

  const submit = async (obj) => {
    try {
      obj.manufactured_date = manufacturedDate
        ? formatDate(manufacturedDate, "date", "backend")
        : null;
      obj.expiry_on = expiryDate
        ? formatDate(expiryDate, "date", "backend")
        : null;

      const response = await addStock(obj);
      if (response?.data?.success) {
        reset();
        setManufacturedDate(null); // Reset date picker
        setExpiryDate(null); // Reset expiry date picker
        dispatch(setOpenStock(false));
        SWLAlert("Success", response?.data?.message, "success");
      }
    } catch (error) {
      console.error("Add Stock error:", error);
      SWLAlert("Error", response?.data?.message, "error");
    }
  };

  const heading = selectedStock?.stock_id ? "Update Stock" : "Add Stock";

  return (
    <>
      {/* Add Stock */}

      <CustomModal heading={heading} onClose={close} isOpen={stocks}>
        <form onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">
                  <small className="text-danger">*</small> Warehouse
                </label>
                <Controller
                  control={control}
                  name="ware_house_id" // Name for the field
                  rules={{ required: "Warehouse is required" }} // Validation rules
                  render={({ field }) => (
                    <Select
                      isSearchable
                      classNamePrefix="react-select"
                      options={warehouseDropdownData}
                      onChange={(e) => field.onChange(e.value)}
                      value={warehouseDropdownData.find(
                        (option) => option.value === field.value
                      )}
                      isDisabled={selectedStock?.stock_id ? true : false}
                    />
                  )}
                />
                {errors.ware_house_id && (
                  <p className="text-danger">{errors.ware_house_id.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">
                  <small className="text-danger">*</small> Product
                </label>
                <Controller
                  control={control}
                  name="product_id"
                  rules={{ required: "product is required" }} // Validation rules
                  render={({ field }) => (
                    <Select
                      classNamePrefix="react-select"
                      options={productDropdownData}
                      onChange={(e) => field.onChange(e.value)}
                      value={productDropdownData.find(
                        (option) => option.value === field.value
                      )}
                      isDisabled={selectedStock?.stock_id ? true : false}
                    />
                  )}
                />
                {errors.product_id && (
                  <p className="text-danger">{errors.product_id.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3">
                <label className="form-label">
                  <small className="text-danger">*</small> Responsible Person
                </label>
                <Controller
                  control={control}
                  name="responsible_user_id" // Name for the field
                  render={({ field }) => (
                    <Select
                      classNamePrefix="react-select"
                      options={userDropdownData}
                      onChange={(e) => field.onChange(e.value)}
                      value={userDropdownData.find(
                        (option) => option.value === field.value
                      )}
                    />
                  )}
                />
                {errors.responsible_user_id && (
                  <p className="text-danger">
                    {errors.responsible_user_id.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label className="form-label">
                  <small className="text-danger">*</small> Quantity
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="1"
                  name="quantity"
                  min={1}
                  {...register("quantity", {
                    required: { value: true, message: "Quantity is required" },
                  })}
                />
                {errors.quantity && (
                  <p className="text-danger">{errors.quantity.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label className="form-label">
                  <small className="text-danger">*</small> Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="0.0"
                  name="price"
                  min={0}
                  {...register("price", {
                    required: { value: true, message: "Price is required" },
                  })}
                />
                {errors.price && (
                  <p className="text-danger">{errors.price.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              <div className="input-blocks">
                <label>Manufactured Date</label>
                <div className="input-groupicon calender-input">
                  <Calendar className="info-img" />
                  <DatePicker
                    type="date"
                    selected={manufacturedDate}
                    onChange={(date) => setManufacturedDate(date)}
                    className="datetimepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholder="Choose Date"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="input-blocks">
                <label>Expiry On</label>
                <div className="input-groupicon calender-input">
                  <Calendar className="info-img" />
                  <DatePicker
                    selected={expiryDate}
                    onChange={(date) => setExpiryDate(date)}
                    className="form-control datetimepicker"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Choose Date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              data-bs-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>

            {isSubmitting ? (
              <button
                className="btn btn-primary-light"
                type="button"
                disabled=""
              >
                <span
                  className="spinner-border spinner-border-sm align-middle me-1"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </button>
            ) : (
              <button type="submit" className="btn btn-submit">
                {selectedStock?.stock_id ? "Update" : "Add"}
              </button>
            )}
          </div>
        </form>
      </CustomModal>

      {/* /Add Stock */}
    </>
  );
};

export default AddStockModal;
