import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";

import rootReducer from "./reducer";
import unitsApi from "./unitsapi";
import productsApi from "./products.api";
import roleApi from "../../services/role.api";
import userApi from "../../services/user.api";
import customerApi from "../../services/customer.api";
import supplierApi from "../../services/supplier.api";
import storeApi from "../../services/store.api";
import warehouseApi from "../../services/warehouse.api";
import stockApi from "../../services/stock.api";
import stockAdjustmentApi from "../../services/stockAdjustment.api";
import stockTransferApi from "../../services/stockTransfer.api";
import commonApi from "../../services/common.api";
import permissionsApi from "../../services/permissions.api";

import { productsReducer } from "./products.slice";
import { commonReducer } from "./slices/common.slice";
import { authReducer } from "./user.slice";
import { unitReducer } from "./units.slice";
import { roleReducer } from "./slices/role.slice";
import { userReducer } from "./slices/user.slice";
import { companiesReducer } from "./slices/companies.slice";
import { tableReducer } from "./slices/table.slice";
import { customerReducer } from "./slices/customer.slice";
import { supplierReducer } from "./slices/supplier.slice";
import { storeReducer } from "./slices/store.slice";
import { warehouseReducer } from "./slices/warehouse.slice";
import { stockReducer } from "./slices/stock.slice";
import { stockAdjustmentReducer } from "./slices/stockAdjustment.slice";
import { stockTransferReducer } from "./slices/stockTransfer.slice";
import { salesReducer } from "./slices/sales.slice";
import { salesReturnReducer } from "./slices/salesreturn.slice";
import { purchaseReducer } from "./slices/purchase.slice";
import { purchaseReturnReducer } from "./slices/purchasereturn.slice";
import { paymentReducer } from "./slices/payment.slice";

import salesApi from "../../services/sales.api";
import purchaseApi from "../../services/purchase.api";
import companiesApi from "../../services/compaines.api";
import tableApi from "../../services/table.api";
import paymentApi from "../../services/payment.api";

export const ErrorLoggerMiddleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status } = action?.payload ?? {
      data: { message: "" },
      status: 200,
    };
    if (status === 401 || status === 404) {
      console.log("Redirecting to login: ");
      // window.location.replace(`${process.env.PUBLIC_URL}/Login`);
      // localStorage.removeItem("user");
      // stackNavigate(ROUTES.SIGNUP);
      // showToast(data?.message || "Redirecting to login, backend error");
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    reducer: rootReducer,
    authReducer,
    unitReducer,
    productsReducer,
    roleReducer,
    userReducer,
    companiesReducer,
    tableReducer,
    customerReducer,
    supplierReducer,
    storeReducer,
    warehouseReducer,
    stockReducer,
    stockAdjustmentReducer,
    stockTransferReducer,
    salesReducer,
    salesReturnReducer,
    purchaseReducer,
    purchaseReturnReducer,
    paymentReducer,
    commonReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [tableApi.reducerPath]: tableApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [supplierApi.reducerPath]: supplierApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [stockApi.reducerPath]: stockApi.reducer,
    [stockAdjustmentApi.reducerPath]: stockAdjustmentApi.reducer,
    [stockTransferApi.reducerPath]: stockTransferApi.reducer,
    [salesApi.reducerPath]: salesApi.reducer,
    [purchaseApi.reducerPath]: purchaseApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      commonApi.middleware,
      unitsApi.middleware,
      productsApi.middleware,
      roleApi.middleware,
      permissionsApi.middleware,
      userApi.middleware,
      companiesApi.middleware,
      tableApi.middleware,
      customerApi.middleware,
      supplierApi.middleware,
      storeApi.middleware,
      warehouseApi.middleware,
      stockApi.middleware,
      stockAdjustmentApi.middleware,
      stockTransferApi.middleware,
      paymentApi.middleware,
      salesApi.middleware,
      purchaseApi.middleware,
      ErrorLoggerMiddleware,
    ]),
});

export default store;

export const dispatch = store.dispatch;
