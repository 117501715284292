import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useAddCompaniesMutation } from "../../../services/compaines.api";
import { setOpenCompanies, setSelectedCompanies } from "../../redux/slices/companies.slice";
import { useGetAllCountryQuery } from "../../../services/common.api";
import { useAddTableMutation } from "../../../services/table.api";
import { setOpenTable, setSelectedTable } from "../../redux/slices/table.slice";
import CustomDropdown from "../../../feature-module/uiinterface/Dropdown";
import { useStoreDropdownQuery } from "../../redux/products.api";

const AddTable = () => {

  const { table, selectedTable } = useSelector(
    (state) => state?.tableReducer
  );

  console.log('selectedTable =>', selectedTable);

  const { data: storeOption } = useStoreDropdownQuery();

  const [addTable, { isLoading }] = useAddTableMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm();

  const onSubmit = async (props) => {
    try {
      const res = await addTable({
        ...props,
        status: props?.status ? 1 : 0,
      }).unwrap();
      if (res?.success == 1) {
        dispatch(setOpenTable(false));
        dispatch(setSelectedTable({}));
        SWLAlert("Success", res?.message, "success");
        reset();
      } else {
        SWLAlert("Error", res?.message, "error");
      }
    } catch (error) {
      SWLAlert("Error", "There was an error adding the Company.", "error");
    }
  };

  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenTable(false));
    reset();
  };

  useEffect(() => {
    if (selectedTable?.id) {
      setValue("id", selectedTable?.id);
      setValue("name", selectedTable?.name);
      setValue("capacity", selectedTable?.capacity);
      setValue("store_id", selectedTable?.store_id);
      setValue("status", selectedTable?.status);
    }
  }, [selectedTable?.company_id, selectedTable]);

  const heading = selectedTable?.id
    ? "Update Table"
    : "Add Table";


  return (
    <div>
      {/* Add Companies */}
      <CustomModal heading={heading} onClose={close} isOpen={table}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <label> <small className="text-danger">*</small>Table Number</label>
                <input
                  type="text"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  className="form-control"
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label><small className="text-danger">*</small>Table Capacity</label>
                <input
                  type="text"
                  {...register("capacity", {
                    required: "capacity is required",
                  })}
                  className="form-control"
                />
                {errors.capacity && (
                  <p className="text-danger">{errors.capacity.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
                      <div className="mb-3 add-product">
                      <label className="form-label">
                          <small className="text-danger">*</small> Store
                        </label>
                        <CustomDropdown
                          {...register("store_id", { required: "Store is required" })}
                          options={storeOption?.data || []}
                          value={watch("store_id")}
                          onChange={(val) => {
                            setValue("store_id", val?.value);
                          }}
                        />
                        {errors.store_id && (
                          <p className="text-danger">{errors.store_id.message}</p>
                        )}
                      </div>
            </div>
            {selectedTable?.id ? (
                <div className="mb-0">
                  <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span className="status-label">Status</span>
                    <input
                      type="checkbox"
                      id="user2"
                      className="check"
                      defaultChecked="true"
                      {...register("status")}
                    />
                    <label htmlFor="user2" className="checktoggle" />
                  </div>
                </div>
              ) : null}
          </div>
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                className="btn btn-primary-light"
                type="button"
                disabled=""
              >
                <span
                  className="spinner-border spinner-border-sm align-middle me-1"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-submit"
                disabled={isLoading}
              >
                {selectedTable?.id ? 'Update' : 'Save'}
              </button>
            )}
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default AddTable;
