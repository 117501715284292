import { baseQuery } from "./baseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

const productsApi = createApi({
  baseQuery: baseQuery,
  reducerPath: "productsApi/reducer",
  tagTypes: ["units", "category", "warehouse", "products", "store"],
  endpoints: (build) => ({
    expired: build.query({
      query: () => {
        return {
          url: "/product/expire/list",
          method: "GET",
        };
      },
      providesTags: ["products"],
    }),
    low: build.query({
      query: () => {
        return {
          url: "/product/low/list",
          method: "GET",
        };
      },
      providesTags: ["products"],
    }),
    categories: build.query({
      query: () => {
        return {
          url: "/category",
          method: "GET",
        };
      },
      providesTags: ["category"],
    }),
    addCategory: build.mutation({
      query: (body) => {
        return {
          url: "/category",
          method: body?.id ? "PUT" : "POST",
          body,
        };
      },
      invalidatesTags: ["category"],
    }),
    deletCategory: build.mutation({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["category"],
    }),
    singleCategory: build.query({
      query: (id) => {
        return {
          url: `/category/${id}`,
          method: "GET",
        };
      },
      providesTags: ["category"],
    }),
    ////////////////////////products///////////////

    products: build.query({
      query: (id) => {
        return {
          url: "/product",
          method: "GET",
        };
      },
      providesTags: ["product"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.title,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),
    addProduct: build.mutation({
      query: (body) => {
        return {
          url: "/product",
          method: body?.id ? "PUT" : "POST",
          body,
        };
      },
      invalidatesTags: ["product"],
    }),
    deletProduct: build.mutation({
      query: (id) => {
        return {
          url: `/product/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["product"],
    }),
    singleProduct: build.query({
      query: (id) => {
        return {
          url: `/product/${id}`,
          method: "GET",
        };
      },
      providesTags: ["product"],
    }),

    productDropdown: build.query({
      query: () => ({
        url: "product/dropdown/list",
        method: "GET",
      }),
      invalidatesTags: ["product"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          value: items?.id,
          label: items?.title,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),

    ///////////////
    // store/dropdown/list
    storeDropdown: build.query({
      query: (body) => {
        return {
          url: "store/dropdown/list",
          method: "GET",
        };
      },
      providesTags: ["store"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.name,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),

    // warehouse/dropdown/list
    warehouseDropdown: build.query({
      query: (body) => {
        return {
          url: "warehouse/dropdown/list",
          method: "GET",
        };
      },
      providesTags: ["warehouse"],
      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.name,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),
    // category/dropdown/list
    categoryDropdown: build.query({
      query: (body) => {
        return {
          url: "category/dropdown/list",
          method: "GET",
        };
      },

      transformResponse(baseQueryReturnValue) {
        const data = { ...baseQueryReturnValue } || {};
        const response = [...(data?.data || [])]?.map((items) => ({
          ...items,
          value: items?.id,
          label: items?.title,
        }));
        return { ...baseQueryReturnValue, data: response } || {};
      },
    }),
  }),
});

export const {
  useSingleCategoryQuery,
  useCategoriesQuery,
  useAddCategoryMutation,
  useDeletCategoryMutation,
  useCategoryDropdownQuery,

  useStoreDropdownQuery,
  useWarehouseDropdownQuery,

  useProductsQuery,
  useAddProductMutation,
  useDeletProductMutation,
  useSingleProductQuery,
  useProductDropdownQuery,

  useExpiredQuery,
  useLowQuery,
} = productsApi;

export default productsApi;
