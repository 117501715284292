export const barcodedata = [
  {
    id: "1",
    product: "Nike Jordan",
    sku: "PT002",
    code: "HG3FK",
    qty: "+",
  },
  {
    id: "2",
    product: "Apple Series 5 Watch",
    sku: "PT003",
    code: "TEUIU7",
    qty: "+",
  },
];
