import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Sliders } from "react-feather";
import Select from "react-select";
import { Globe, User } from "react-feather";
import ImageWithBasePath from "../../img/imagewithbasebath";
import Breadcrumbs from "../../breadcrumbs";

import { warehousedata } from "../../json/warehouse";
import Table from "../../pagination/datatable";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import {
  useDeleteWarehouseMutation,
  useGetAllWarehouseQuery
} from "../../../services/warehouse.api";
import { formatDate } from "../../../utils/datePipe";
import LoadingSpinner from "../../../InitialPage/Sidebar/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { setOpenWarehouse, setSelectedWarehouse } from "../../redux/slices/warehouse.slice";
import { Tooltip } from "antd";
import { OverlayTrigger } from "react-bootstrap";
import { ChevronUp, PlusCircle, RotateCcw } from "feather-icons-react/build/IconComponents";
import WarehouseModal from "./warehouseModal";

const WareHouses = () => {

  const dispatch = useDispatch();
  const toolbarData = useSelector((state) => state.toggle_header);
  console.log('toolbarData =>', toolbarData);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const {
    data: warehouseList,
    isLoading,
    isFetching,
  } = useGetAllWarehouseQuery({}, { refetchOnMountOrArgChange: false });

  console.log('warehouseList =>', warehouseList);

  const [deleteWarehouse, { isLoading: deleting }] = useDeleteWarehouseMutation();

  const warehouselistdata = warehousedata;
  const [searchText, setSearchText] = useState("");
  const filteredData = warehouselistdata.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      sorter: (a, b) => a.contact_person.length - b.contact_person.length,
    },

    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },

    {
      title: "TotalProducts",
      dataIndex: "totalProducts",
      sorter: (a, b) => a.totalProducts.length - b.totalProducts.length,
    },
    /* {
      title: "Stock",
      dataIndex: "stock",
      sorter: (a, b) => a.stock.length - b.stock.length,
    }, */
    {
      title: "Qty",
      dataIndex: "qty",
      sorter: (a, b) => a.qty.length - b.qty.length,
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      render: (date) => (
        <span >
          {date ? formatDate(date) : "-"}
        </span>
      ),
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      render: (text) => (
        <span
          className={`badge ${text == "1" ? "badge-linesuccess" : "badge-bgdanger"
            }`}
        >
          {text == "1" ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, dt) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 edit-icon p-2"
              onClick={() => {
                dispatch(setOpenWarehouse(true));
                dispatch(setSelectedWarehouse({ ...dt, type: 'view' }));
              }}
            >
              <i data-feather="eye" className="feather-eye"></i>
            </Link>
            <Link
              className="me-2 p-2"
              onClick={() => {
                dispatch(setOpenWarehouse(true));
                dispatch(setSelectedWarehouse({ ...dt, type: 'edit' }));
              }}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link className="confirm-text p-2"
              onClick={() => showConfirmationAlert(dt?.id)}>
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const options = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "140923", label: "14 09 23" },
    { value: "110923", label: "11 09 23" },
  ];
  const optionsTwo = [
    { label: "Choose Store Name", value: "" },
    { label: "Benjamin", value: "Benjamin" },
    { label: "Ellen", value: "Ellen" },
    { label: "Freda", value: "Freda" },
    { label: "Kaitlin", value: "Kaitlin" },
  ];

  const countries = [
    { label: "Choose Country", value: "" },
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
  ];

  const options1 = [
    { value: "choose", label: "Choose" },
    { value: "steven", label: "Steven" },
    { value: "gravely", label: "Gravely" },
  ];

  const options2 = [
    { value: "choose", label: "Choose" },
    { value: "uk", label: "United Kingdom" },
    { value: "us", label: "United States" },
  ];


  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteWarehouse(id).then(() => {
          MySwal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            className: "btn btn-success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        });
      } else {
        MySwal.close();
      }
    });
  };
  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );


  return (
    <>
      {isLoading || isFetching ? <LoadingSpinner /> : null}
      <div className="page-wrapper">
        <div className="content">
          {/* <Breadcrumbs
          maintitle="Warehouse"
          subtitle="Manage Your Warehouse"
          addButton="Add New Warehouse"
        /> */}

          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Warehouse List</h4>
                <h6>Manage Your Warehouse</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={toolbarData ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!toolbarData));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <button
                className="btn btn-added"
                onClick={() => {
                  dispatch(setOpenWarehouse(true));
                  dispatch(setSelectedWarehouse({ type: 'add' }));
                }}
              >
                <PlusCircle className="me-2" />
                Add New Warehouse
              </button>
            </div>
          </div>

          {/* /warehouse list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <Link
                    className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                      }`}
                    id="filter_search"
                  >
                    <Filter
                      className="filter-icon"
                      onClick={toggleFilterVisibility}
                    />
                    <span onClick={toggleFilterVisibility}>
                      <ImageWithBasePath
                        src="assets/img/icons/closes.svg"
                        alt="img"
                      />
                    </span>
                  </Link>
                </div>
                <div className="form-sort stylewidth">
                  <Sliders className="info-img" />

                  <Select
                    classNamePrefix="react-select"
                    className="img-select"
                    options={options}
                    placeholder="Sort by Date"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          classNamePrefix="react-select"
                          className="img-select"
                          options={optionsTwo}
                          placeholder="Choose Store Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Globe className="info-img" />
                        <Select
                          classNamePrefix="react-select"
                          className="img-select"
                          options={countries}
                          placeholder="Choose Country"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={warehouseList ? warehouseList.data : []} />
              </div>
            </div>
          </div>
          {/* /warehouse list */}
          <WarehouseModal />
        </div>

        <div>
          {/* Add Warehouse */}
          <div className="modal fade" id="add-units">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
              <div className="modal-content">
                <div className="page-wrapper-new p-0">
                  <div className="content p-0">
                    <div className="modal-header border-0 custom-modal-header">
                      <div className="page-title">
                        <h4>Add Warehouse</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                      <form>
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="info" className="feather-info me-2" />
                            </span>
                            Warehouse Info
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Contact Person</label>
                              <Select
                                classNamePrefix="react-select"
                                options={options1}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 war-add">
                              <label className="mb-2">Phone Number</label>
                              <input
                                className="form-control"
                                id="phone"
                                name="phone"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Work Phone</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Email</label>
                              <input type="email" className="form-control" />
                            </div>
                          </div>
                          <div className="modal-title-head">
                            <h6>
                              <span>
                                <i data-feather="map-pin" />
                              </span>
                              Location
                            </h6>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Address 1</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Address 2</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Country</label>
                              <Select
                                classNamePrefix="react-select"
                                options={options2}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">State</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 mb-0">
                              <label className="form-label">City</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 mb-0">
                              <label className="form-label">Zipcode</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-submit">
                            Create Warehouse
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Add Warehouse */}
          {/* Edit Warehouse */}
          <div className="modal fade" id="edit-units">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
              <div className="modal-content">
                <div className="page-wrapper-new p-0">
                  <div className="content p-0">
                    <div className="modal-header border-0 custom-modal-header">
                      <div className="page-title">
                        <h4>Edit Warehouse</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                      <form>
                        <div className="modal-title-head">
                          <h6>
                            <span>
                              <i data-feather="info" className="feather-info me-2" />
                            </span>
                            Warehouse Info
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="Legendary"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Contact Person</label>
                              <Select
                                classNamePrefix="react-select"
                                options={options1}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 war-edit-phone">
                              <label className="mb-2">Phone Number</label>
                              <input
                                className="form-control"
                                id="phone2"
                                name="phone"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 war-edit-phone">
                              <label className="form-label">Work Phone</label>
                              <input
                                className="form-control"
                                id="phone3"
                                name="phone"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                defaultValue="stevenlegendary@example.com"
                              />
                            </div>
                          </div>
                          <div className="modal-title-head">
                            <h6>
                              <span>
                                <i data-feather="map-pin" />
                              </span>
                              Location
                            </h6>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Address 1</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="Admiral Street"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input-blocks">
                              <label className="form-label">Address 2</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="Aire Street"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-blocks">
                              <label>Country</label>
                              <Select
                                classNamePrefix="react-select"
                                options={options2}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">State</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="East England"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 mb-0">
                              <label className="form-label">City</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="Leeds"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3 mb-0">
                              <label className="form-label">Zipcode</label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue="LS1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-submit">
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default WareHouses;
