export const set_storage_item = (key, value) => {
  localStorage?.setItem(key, value);
};

export const get_storage_item = (key) => {
  return localStorage?.getItem(key);
};

export const removeItem = (key) => {
  return localStorage?.removeItem(key);
};

export const getQueryParam = (key) => {
  const queryString = window?.location?.search;
  const urlParams = new URLSearchParams(queryString);
  const value = urlParams.get(key);
  return value;
};

export const setQueryParams = (key, value) => {
  let paramsList = "?";

  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    `?${key}=${value}`;

  window.history.pushState(
    { path: newurl },
    "",
    list?.length ? paramsList : newurl
  );
};

export const deleteQueryParams = (key) => {
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  urlParams.delete(key);

  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    `?${urlParams}`;
  window.history.pushState({ path: newurl }, "", newurl);
};
