import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import {
  setOpenCategory,
  setSelectedCategory,
} from "../../redux/products.slice";
import { useAddCategoryMutation } from "../../redux/products.api";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import LoadingSpinner from "../../../InitialPage/Sidebar/LoadingSpinner";

const AddCategoryList = () => {
  const { category, selectedCategory } = useSelector(
    (state) => state?.productsReducer
  );

  const { handleSubmit, watch, register, getValues, reset, setValue } =
    useFormContext();
  const values = getValues();

  const [addProduct, { isLoading }] = useAddCategoryMutation();
  const submit = (props) => {
    addProduct({ ...props, status: props?.status ? 1 : 0 }).then((res) => {
      if (res?.data?.success) {
        dispatch(setOpenCategory(false));
        dispatch(setSelectedCategory({}));
        SWLAlert("Success", res?.data?.message, "success");
      } else {
        SWLAlert("Error", res?.data?.message, "error");
      }
    });
  };
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenCategory(false));
    reset({
      title: "",
      status: 1,
      id: null,
    });
  };

  useEffect(() => {
    if (selectedCategory?.id) {
      setValue("title", selectedCategory?.title);
      setValue("id", selectedCategory?.id);
      setValue("status", selectedCategory?.status);
    }
  }, [selectedCategory?.id, selectedCategory]);

  const heading = selectedCategory?.id ? "Update Category" : "Create Category";
  return (
    <>
      {isLoading ? <LoadingSpinner /> : null}
      <CustomModal heading={heading} onClose={close} isOpen={category}>
        <form onSubmit={handleSubmit(submit)} noValidate id="category-form">
          <div className="mb-3">
            <label className="form-label">Category</label>
            <input
              type="text"
              className="form-control"
              {...register("title")}
              defaultValue={watch("title")}
            />
          </div>

          {selectedCategory?.id ? (
            <div className="mb-0">
              <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                <span className="status-label">Status</span>
                <input
                  type="checkbox"
                  id="user2"
                  className="check"
                  defaultChecked="true"
                  {...register("status")}
                  value={watch("status")}
                />
                <label htmlFor="user2" className="checktoggle" />
              </div>
            </div>
          ) : null}
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="btn btn-submit"
              type="submit"
              disabled={!getValues("title")}
            >
              {heading}
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default AddCategoryList;
