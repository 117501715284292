export const CustomerData = [
  {
    id: 1,
    CustomerName: "Thomas",
    Code: "201",
    Customer: "Thomas",
    Email: "thomas@exmple.com",
    Phone: "+12163547758",
    Country: "Germany",
  },
  {
    id: 2,
    CustomerName: "Rose",
    Code: "201",
    Customer: "Rose",
    Email: "rose@exmple.com",
    Phone: "+11367529510",
    Country: "USA",
  },
  {
    id: 3,
    CustomerName: "Benjamin",
    Code: "203",
    Customer: "Benjamin",
    Email: "benjamin@exmple.com",
    Phone: "+15362789414",
    Country: "Japan",
  },
  {
    id: 4,
    CustomerName: "Kaitlin",
    Code: "204",
    Customer: "Kaitlin",
    Email: "kaitlin@exmple.com",
    Phone: "+18513094627",
    Country: "Austria",
  },
  {
    id: 5,
    CustomerName: "Lilly",
    Code: "205",
    Customer: "Lilly",
    Email: "lilly@exmple.com",
    Phone: "+14678219025",
    Country: "India",
  },
  {
    id: 6,
    CustomerName: "Freda",
    Code: "206",
    Customer: "Freda",
    Email: "freda@exmple.com",
    Phone: "+10913278319",
    Country: "France",
  },
  {
    id: 7,
    CustomerName: "Walk-in-Customer",
    Code: "01",
    Customer: "Walk-in-Customer",
    Email: "customer01@exmple.com",
    Phone: "+19125852947",
    Country: "Belgium",
  },
  {
    id: 8,
    CustomerName: "Maybelle",
    Code: "207",
    Customer: "Maybelle",
    Email: "maybelle@exmple.com",
    Phone: "+19125852947",
    Country: "Italy",
  },
  {
    id: 9,
    CustomerName: "Ellen",
    Code: "208",
    Customer: "Ellen",
    Email: "ellen@exmple.com",
    Phone: "+19756194733",
    Country: "Canada",
  },
  {
    id: 10,
    CustomerName: "Walk-in-Customer",
    Code: "02",
    Customer: "Walk-in-Customer",
    Email: "customer02@exmple.com",
    Phone: "+19167850925",
    Country: "Greece",
  },
];
