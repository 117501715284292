export const brandlistdata = [
  {
    id: 1,
    brand: "Starbucks",
    logo: "assets/img/brand/starbucks.png",
    createdon: "25 May 2023",
    status: "Active",
  },
  {
    id: 2,
    brand: "Coca-Cola",
    logo: "assets/img/brand/coca-cola.png",
    createdon: "10 Jun 2023",
    status: "Active",
  },
  {
    id: 3,
    brand: "Heineken",
    logo: "assets/img/brand/heineken.png",
    createdon: "15 Jul 2023",
    status: "Active",
  },
  {
    id: 4,
    brand: "Marriott",
    logo: "assets/img/brand/marriott.png",
    createdon: "01 Aug 2023",
    status: "Active",
  },
  {
    id: 5,
    brand: "Hilton",
    logo: "assets/img/brand/hilton.png",
    createdon: "20 Aug 2023",
    status: "Active",
  },
  {
    id: 6,
    brand: "Red Bull",
    logo: "assets/img/brand/red-bull.png",
    createdon: "05 Sep 2023",
    status: "Active",
  },
  {
    id: 7,
    brand: "Pepsi",
    logo: "assets/img/brand/pepsi.png",
    createdon: "18 Sep 2023",
    status: "Active",
  },
  {
    id: 8,
    brand: "Tropicana",
    logo: "assets/img/brand/tropicana.png",
    createdon: "28 Sep 2023",
    status: "Active",
  },
  {
    id: 9,
    brand: "Nestle",
    logo: "assets/img/brand/nestle.png",
    createdon: "10 Oct 2023",
    status: "Active",
  },
  {
    id: 10,
    brand: "Ritz-Carlton",
    logo: "assets/img/brand/ritz-carlton.png",
    createdon: "20 Oct 2023",
    status: "Active",
  },
];
