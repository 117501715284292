export const ManageStocksdata = [
  {
    id: "1",
    Warehouse: "Central Liquor Depot",
    Shop: "Cocktail Lounge",
    Product: {
      Name: "Gin - Bombay Sapphire",
      Image: "assets/img/products/gin.png",
    },
    Date: "15 Jun 2023",
    Person: {
      Name: "Emma Johnson",
      Image: "assets/img/users/user-10.jpg",
    },
    Quantity: 50,
  },
  {
    id: "2",
    Warehouse: "Main Kitchen Supplies",
    Shop: "Fine Dining Restaurant",
    Product: {
      Name: "Salmon Fillet",
      Image: "assets/img/products/salmon.png",
    },
    Date: "20 Jun 2023",
    Person: {
      Name: "Michael Brown",
      Image: "assets/img/users/user-12.jpg",
    },
    Quantity: 30,
  },
  {
    id: "3",
    Warehouse: "Beverage Storage",
    Shop: "Bar & Grill",
    Product: {
      Name: "Tequila - Reposado",
      Image: "assets/img/products/tequila.png",
    },
    Date: "25 Jun 2023",
    Person: {
      Name: "Sophia Martinez",
      Image: "assets/img/users/user-09.jpg",
    },
    Quantity: 40,
  },
  {
    id: "4",
    Warehouse: "Dessert Station",
    Shop: "Patisserie",
    Product: {
      Name: "Macarons",
      Image: "assets/img/products/macarons.png",
    },
    Date: "10 Jul 2023",
    Person: {
      Name: "Daniel White",
      Image: "assets/img/users/user-11.jpg",
    },
    Quantity: 100,
  },
  {
    id: "5",
    Warehouse: "Fresh Produce",
    Shop: "Farm-to-Table Restaurant",
    Product: {
      Name: "Organic Tomatoes",
      Image: "assets/img/products/tomatoes.png",
    },
    Date: "05 Jul 2023",
    Person: {
      Name: "Olivia Green",
      Image: "assets/img/users/user-13.jpg",
    },
    Quantity: 200,
  },
  {
    id: "6",
    Warehouse: "Coffee Supplies",
    Shop: "Café",
    Product: {
      Name: "Espresso Beans",
      Image: "assets/img/products/espresso-beans.png",
    },
    Date: "18 Jul 2023",
    Person: {
      Name: "William Taylor",
      Image: "assets/img/users/user-14.jpg",
    },
    Quantity: 80,
  },
  {
    id: "7",
    Warehouse: "Specialty Meats",
    Shop: "Steakhouse",
    Product: {
      Name: "Wagyu Beef",
      Image: "assets/img/products/wagyu-beef.png",
    },
    Date: "22 Jul 2023",
    Person: {
      Name: "Sophie Anderson",
      Image: "assets/img/users/user-15.jpg",
    },
    Quantity: 60,
  },
  {
    id: "8",
    Warehouse: "Pantry Essentials",
    Shop: "Family Restaurant",
    Product: {
      Name: "Pasta - Penne",
      Image: "assets/img/products/penne-pasta.png",
    },
    Date: "30 Jul 2023",
    Person: {
      Name: "David Clark",
      Image: "assets/img/users/user-16.jpg",
    },
    Quantity: 120,
  },
  {
    id: "9",
    Warehouse: "Barware Supplies",
    Shop: "Pub",
    Product: {
      Name: "Beer Mugs",
      Image: "assets/img/products/beer-mug.png",
    },
    Date: "02 Aug 2023",
    Person: {
      Name: "Lucas Baker",
      Image: "assets/img/users/user-17.jpg",
    },
    Quantity: 90,
  },
  {
    id: "10",
    Warehouse: "Frozen Foods",
    Shop: "Ice Cream Parlor",
    Product: {
      Name: "Ice Cream - Vanilla",
      Image: "assets/img/products/ice-cream.png",
    },
    Date: "05 Aug 2023",
    Person: {
      Name: "Sophia Lee",
      Image: "assets/img/users/user-18.jpg",
    },
    Quantity: 150,
  },
];
