import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url } from "../../env";

export const baseQuery = fetchBaseQuery({
  baseUrl: base_url,
  prepareHeaders: async (header) => {
    const token = localStorage.getItem("token");
    if (token) {
      header.set("Authorization", `Bearer ${token}`);
      header.set("X-Requested-With", `XMLHttpRequest'`);
      header.set("Content-Type", `application/json`);
    }
    return header;
  },
});
