import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  customers: false,
  selectedCustomer: {}
};

const customersApi = createSlice({
  name: 'customerReducer',
  initialState,
  reducers: {
    setOpenCustomer: (state, action) => {
      state.customers = action.payload;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },

  },
});

export const {
setOpenCustomer,
setSelectedCustomer
} = customersApi.actions;
export const customerReducer = customersApi.reducer;
