import React from "react";

const CustomModal = ({ isOpen, onClose, heading, children, size }) => {
  // console.log('isOpen =>', isOpen);

  const getSizeClass = (size) => {
    switch (size) {
      case "sm":
        return "modal-sm";
      case "md":
        return "modal-md";
      case "lg":
        return "modal-lg";
      case "xl":
        return "modal-xl";
      default:
        return "";
    }
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      id="add-units"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div
        className={`modal-dialog modal-dialog-centered custom-modal-two ${getSizeClass(
          size
        )}`}
      >
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header">
                <div className="page-title">
                  <h4>{heading}</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={onClose}
                  aria-label="Close"
                  // data-bs-dismiss="modal"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
