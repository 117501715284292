import React, { useEffect } from "react";
import Select from "react-select";
import ImageWithBasePath from "../../img/imagewithbasebath";
import { Link } from "react-router-dom";
import { MinusCircle, PlusCircle } from "react-feather";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setAddStockAdjModal, setOpenStockAdjustment } from "../../redux/slices/stockAdjustment.slice";
import { Controller, useForm } from "react-hook-form";
import { useGetWarehouseDropdownQuery } from "../../../services/warehouse.api";
import { useProductDropdownQuery } from "../../redux/products.api";
import { useAddStockAdjustmentMutation } from "../../../services/stockAdjustment.api";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useGetUserDropdownQuery } from "../../../services/user.api";

const AddStockadJustmentModal = () => {

  const dispatch = useDispatch();
  const { addStockAdjustmentsModal } = useSelector(
    (state) => state.stockAdjustmentReducer
  );
  const { stockAdjustments, selectedStockAdjustment } = useSelector(
    (state) => state?.stockAdjustmentReducer
  );

  console.log('selectedStockAdjustment =>', selectedStockAdjustment)

  const close = () => {
    dispatch(setOpenStockAdjustment(false));
    reset();
  };

  useEffect(() => {
    if (selectedStockAdjustment?.adjustment_id) {
      setValue("id", selectedStockAdjustment?.adjustment_id);
      setValue("product_id", selectedStockAdjustment?.product_id);
      setValue("ware_house_id", selectedStockAdjustment?.warehouse_id);
      setValue("responsible_user_id", selectedStockAdjustment?.responsible_user_id);
      setValue("adjustment_type", selectedStockAdjustment?.adjustment_type);
      setValue("quantity", selectedStockAdjustment?.quantity);
      setValue("reason", selectedStockAdjustment?.reason);
    }
  }, [selectedStockAdjustment?.adjustment_id, selectedStockAdjustment]);

  const { data: warehouseDropdown } = useGetWarehouseDropdownQuery();
  const { data: productDropdown } = useProductDropdownQuery();
  const { data: userDropdown } = useGetUserDropdownQuery();

  const warehouseDropdownData = warehouseDropdown?.data || [];
  const productDropdownData = productDropdown?.data || [];
  const userDropdownData = userDropdown?.data || [];

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  const [addStockAdjustment, { isLoading: isSubmitting }] = useAddStockAdjustmentMutation();

  const submit = async (obj) => {

    try {
      const res = await addStockAdjustment(obj);
      if (res?.data?.success) {
        close();
        SWLAlert("Success", res?.data?.message, "success");
      }else{
        SWLAlert("Error", res?.data?.message, "error");
      }
    } catch (error) {
      console.error("Add Stock error:", error);
      SWLAlert("Error", res?.data?.message, "error");
    }
  };

  const optionsChoose = [
    { value: "choose", label: "Choose" },
    { value: "lobarHandy", label: "Lobar Handy" },
    { value: "quaintWarehouse", label: "Quaint Warehouse" },
  ];

  const optionsAddition = [
    { value: "addition", label: "Addition" },
    { value: "subtraction", label: "Subtraction" },
  ];

  const optionsStevenGravely = [
    { value: "choose", label: "Choose" },
    { value: "steven", label: "Steven" },
    { value: "gravely", label: "Gravely" },
  ];
  const heading = selectedStockAdjustment?.adjustment_id
  ? "Update Stock Adjustment"
  : "Add Stock Adjustment";
  return (
    <CustomModal
      heading={heading}
      onClose={close}
      isOpen={stockAdjustments}
    >
      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label"><small className="text-danger">*</small> Warehouse</label>
              <Controller
                control={control}
                name="ware_house_id" // Name for the field
                rules={{ required: "Warehouse is required" }} // Validation rules
                render={({ field }) => (
                  <Select
                    classNamePrefix="react-select"
                    options={warehouseDropdownData}
                    onChange={(e) => field.onChange(e.value)}
                    value={warehouseDropdownData.find(
                      (option) => option.value === field.value
                    )
                    }
                    isDisabled={selectedStockAdjustment?.adjustment_id ? true : false}
                  />
                )}
              />
              {errors.ware_house_id && (
                <p className="text-danger">{errors.ware_house_id.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label"><small className="text-danger">*</small> Product</label>
              <Controller
                control={control}
                name="product_id"
                rules={{ required: "product is required" }} // Validation rules
                render={({ field }) => (
                  <Select
                    classNamePrefix="react-select"
                    options={productDropdownData}
                    onChange={(e) => field.onChange(e.value)}
                    value={productDropdownData.find(
                      (option) => option.value === field.value
                    )
                    }
                    isDisabled={selectedStockAdjustment?.adjustment_id ? true : false}
                  />
                )}
              />
              {errors.product_id && (
                <p className="text-danger">{errors.product_id.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label"><small className="text-danger">*</small> Responsible Person</label>
              <Controller
                control={control}
                name="responsible_user_id" // Name for the field
                render={({ field }) => (
                  <Select
                    classNamePrefix="react-select"
                    options={userDropdownData}
                    onChange={(e) => field.onChange(e.value)}
                    value={userDropdownData.find(
                      (option) => option.value === field.value
                    )
                    }

                  />
                )}
              />
              {errors.responsible_user_id && (
                <p className="text-danger">{errors.responsible_user_id.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label className="form-label"><small className="text-danger">*</small> Quantity Type</label>
              <Controller
                control={control}
                name="adjustment_type" // Name for the field
                rules={{ required: "Adjustment type is required" }} // Validation rules
                render={({ field }) => (
                  <Select
                    classNamePrefix="react-select"
                    options={optionsAddition}
                    onChange={(e) => field.onChange(e.value)}
                    value={optionsAddition.find(
                      (option) => option.value === field.value
                    )
                    }
                    isDisabled={selectedStockAdjustment?.adjustment_id ? true : false}

                  />
                )}
              />
              {errors.adjustment_type && (
                <p className="text-danger">{errors.adjustment_type.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-blocks">
              <label className="form-label"><small className="text-danger">*</small> Quantity</label>
              <input
                type="number"
                className="form-control"
                placeholder="1"
                name="quantity"
                min={1}
                {...register("quantity", {
                  required: { value: true, message: "Quantity is required" },
                })}
              />
              {errors.quantity && (
                <p className="text-danger">{errors.quantity.message}</p>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks summer-description-box">
              <label>Notes</label>
              <textarea
                name="reason"
                className="form-control"
                defaultValue={""}
                {...register("reason")}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            onClick={close}
          >
            Cancel
          </button>
          { isSubmitting ? (
              <button
                className="btn btn-primary-light"
                type="button"
                disabled=""
              >
                <span
                  className="spinner-border spinner-border-sm align-middle me-1"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </button>
            ) :
          (
          <button type="submit" className="btn btn-submit">
            { selectedStockAdjustment.adjustment_id ? 'Update' :  'Add' }
          </button>
        )}
        </div>
      </form>
    </CustomModal>
  );
};

export default AddStockadJustmentModal;
