export const lowstockdata = [
  {
    id: 1,
    img: "assets/img/products/whiskey-bottle.png",
    warehouse: "Central Distribution Center",
    store: "The Whiskey Bar",
    product: "Single Malt Scotch",
    category: "Whiskey",
    sku: "DR009",
    qty: "8",
    qtyalert: "10",
  },
  {
    id: 2,
    img: "assets/img/products/red-wine.png",
    warehouse: "Main Storage Facility",
    store: "Bistro de Vin",
    product: "Red Wine - Merlot",
    category: "Wines",
    sku: "DR002",
    qty: "12",
    qtyalert: "15",
  },
  {
    id: 3,
    img: "assets/img/products/craft-beer.png",
    warehouse: "Beverage Warehouse",
    store: "Taproom & Brewery",
    product: "Craft Beer - IPA",
    category: "Beers",
    sku: "DR007",
    qty: "5",
    qtyalert: "10",
  },
  {
    id: 4,
    img: "assets/img/products/coffee-beans.png",
    warehouse: "Coffee Supplies Warehouse",
    store: "Café Aroma",
    product: "Espresso Coffee Beans",
    category: "Coffee",
    sku: "DR010",
    qty: "7",
    qtyalert: "10",
  },
  {
    id: 5,
    img: "assets/img/products/martini-glass.png",
    warehouse: "Glassware Storage",
    store: "Martini Lounge",
    product: "Martini Glasses",
    category: "Glassware",
    sku: "FD012",
    qty: "9",
    qtyalert: "12",
  },
  {
    id: 6,
    img: "assets/img/products/cognac-bottle.png",
    warehouse: "Premium Spirits Vault",
    store: "Cognac Bar",
    product: "Fine Cognac",
    category: "Spirits",
    sku: "DR011",
    qty: "6",
    qtyalert: "10",
  },
  {
    id: 7,
    img: "assets/img/products/steak.png",
    warehouse: "Meat Storage Facility",
    store: "Steakhouse",
    product: "Prime Ribeye Steak",
    category: "Meat",
    sku: "FD013",
    qty: "8",
    qtyalert: "10",
  },
  {
    id: 8,
    img: "assets/img/products/olive-oil.png",
    warehouse: "Pantry Supplies",
    store: "Mediterranean Restaurant",
    product: "Extra Virgin Olive Oil",
    category: "Ingredients",
    sku: "FD014",
    qty: "7",
    qtyalert: "10",
  },
  {
    id: 9,
    img: "assets/img/products/cocktail-shaker.png",
    warehouse: "Bar Accessories Depot",
    store: "Cocktail Lounge",
    product: "Cocktail Shakers",
    category: "Barware",
    sku: "FD015",
    qty: "4",
    qtyalert: "5",
  },
  {
    id: 10,
    img: "assets/img/products/champagne-bottle.png",
    warehouse: "Sparkling Drinks Warehouse",
    store: "Champagne Bar",
    product: "Champagne - Brut",
    category: "Sparkling Wines",
    sku: "DR012",
    qty: "3",
    qtyalert: "5",
  },
];
