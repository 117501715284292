import React from "react";

export default function GrowLoader() {
  return (
    <div className="page-wrapper ">
      <div className="card-body">
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}
