import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronUp, Filter, Sliders, Zap } from "react-feather";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RotateCcw } from "feather-icons-react/build/IconComponents";
import { DatePicker } from "antd";
import { useAddPermissionMutation, useGetPermissionByIdQuery } from "../../services/permissions.api";
import { get_storage_item } from "../../utils/Method";
import LoadingSpinner from "../../InitialPage/Sidebar/LoadingSpinner";
import { SWLAlert } from "../uiinterface/SWLAlert";

const Permissions = () => {
  const roleId = get_storage_item("roleId");
  const [permissionData, setPermissionData] = useState([]);
  const { data: permissions, isLoading, isFetching } = useGetPermissionByIdQuery(roleId);
  // console.log("----->>", permissions);
  
  const [addPermission, {isLoading: isSubmit} ] = useAddPermissionMutation();
  
  // console.log("isSubmit----->>", isSubmit);

  useEffect(() => {
    if (!isLoading && permissions?.data) {
      setPermissionData(permissions.data);
    }
  }, [isLoading, permissions]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleCheckbox = (e, col, index) => {
    const isSelected = e.target.checked ? 1 : 0;

    // Update the specific item in the array
    const updatedPermissions = permissionData.map((perm, idx) =>
      idx === index ? { ...perm, [col]: isSelected } : perm
    );

    // Set the updated state
    setPermissionData(updatedPermissions);
  };

  const onSubmit = async () => {
    try {
      const data = {
        roleId: roleId,
        permission: permissionData
      }
      const res = await addPermission(data).unwrap();
      if (res?.success == 1) {
        SWLAlert("Success", res?.message, "success");
      } else {
        SWLAlert("Error", res?.message, "error");
      }
    } catch (error) {
      SWLAlert("Error", "There was an error adding the permission.", "error");
    }
  };

  return (
    <>
     {isLoading || isFetching  ? <LoadingSpinner /> : null}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Permission</h4>
                <h6>Manage your permissions</h6>
              </div>
            </div>
          </div>
          {/*  list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top justify-content-end">
                <div >
                {isSubmit ? (
                <button
                  className="btn btn-primary-light"
                  type="button"
                  disabled=""
                >
                  <span
                    className="spinner-border spinner-border-sm align-middle me-1"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </button>
              ) : (
                <button
                className="btn btn-submit"
                onClick={onSubmit}
                disabled={isSubmit}
              >
                Update Permission
              </button>
              )}
                 
                </div>
              </div>

              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th>
                      <th>Modules</th>
                      <th>Create</th>
                      <th>Edit</th>
                      <th>Delete</th>
                      <th>View</th>
                      <th className="no-sort">Allow all</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissions?.data?.map((pd, index) => {
                      return (
                        <tr key={pd?.id}>
                          <td>
                            <label className="checkboxs">
                              <input type="checkbox" id={pd.id} />
                              <span className="checkmarks" />
                            </label>
                          </td>
                          <td>{pd.title}</td>
                          <td>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                value={pd._add}
                                name={`create_${pd.id}`}
                                defaultChecked={pd._add !== 0}
                                onChange={(e) =>
                                  handleCheckbox(e, "_add", index)
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                          <td>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                value={pd._update}
                                name={`edit_${pd.id}`}
                                defaultChecked={pd._update !== 0}
                                onChange={(e) =>
                                  handleCheckbox(e, "_update", index)
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                          <td>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                value={pd._delete}
                                name={`delete_${pd.id}`}
                                defaultChecked={pd._delete !== 0}
                                onChange={(e) =>
                                  handleCheckbox(e, "_delete", index)
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                          <td>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                value={pd._view}
                                name={`view_${pd.id}`}
                                defaultChecked={pd._view !== 0}
                                onChange={(e) =>
                                  handleCheckbox(e, "_view", index)
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                          <td>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                value={pd._all}
                                name={`allowall_${pd.id}`}
                                defaultChecked={pd._all !== 0}
                                onChange={(e) =>
                                  handleCheckbox(e, "_all", index)
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default Permissions;
