import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Calendar,
  CloudOff,
  PlusCircle,
} from "feather-icons-react/build/IconComponents";
import Select from "react-select";
import { DatePicker } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ImageWithBasePath from "../../img/imagewithbasebath";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useSelector } from "react-redux";
import { setSalesModal } from "../../redux/slices/sales.slice";
import { dispatch } from "../../redux/store";
import { useGetCustomersDropdownQuery } from "../../../services/customer.api";
import { Controller, useForm } from "react-hook-form";
import { formatDate } from "../../../utils/datePipe";
import {
  useProductDropdownQuery,
  useSingleProductQuery,
} from "../../redux/products.api";
import {
  useAddSalesMutation,
  useGetSingleSalesQuery,
  useUpdateSaleMutation,
} from "../../../services/sales.api";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { MinusCircle } from "react-feather";
import moment from "moment";
import LoadingSpinner from "../../../InitialPage/Sidebar/LoadingSpinner";

const AddSalesModal = () => {
  const { salesModal } = useSelector((state) => state.salesReducer);

  const isViewModal = salesModal?.modalType === "View";
  const isAddModal = salesModal?.modalType === "Add";
  const isEditModal = salesModal?.modalType === "Edit";
  const modalData = salesModal?.modalData;

  const close = () => {
    dispatch(setSalesModal({ modal: false }));
  };
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allProducts, setAllProducts] = useState([]);

  const { data: customerDropdownList } = useGetCustomersDropdownQuery();
  const { data: productDropdown } = useProductDropdownQuery();

  const customerDropdownData = customerDropdownList?.data || [];

  const getSingleProduct = (id) => {
    setSelectedProduct(id);
  };

  const { data: singleProduct } = useSingleProductQuery(selectedProduct, {
    skip: selectedProduct === null,
  });

  const productData = singleProduct?.data
    ? {
        product_id: singleProduct?.data?.id,
        quantity: singleProduct?.data?.qty_alert,
        discount: singleProduct?.data?.discount,
        price: 100,
        tax_id: 1,
        tax_amount: 5,
      }
    : null;

  useEffect(() => {
    if (singleProduct?.data) {
      setAllProducts((prevProducts) => [...prevProducts, productData]);
    }
  }, [singleProduct?.data]);

  const totals = allProducts?.reduce(
    (acc, pd) => {
      acc.qty += pd?.qty || 0;
      acc.price += pd?.price || 0;
      acc.discount += pd?.discount || 0;
      acc.tax_amount += pd?.tax_amount || 0;
      return acc;
    },
    { qty: 0, price: 0, discount: 0, tax_amount: 0 }
  );

  const removeProductById = (id) => {
    setAllProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== id)
    );
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(addSalesScehma),
  });

  const [addSale, { isLoading }] = useAddSalesMutation();
  const [updateSales, { isLoading: isUpdating }] = useUpdateSaleMutation();

  const { data: singleSale, isLoading: isSaleLoading } = useGetSingleSalesQuery(
    modalData?.sale_id,
    {
      skip: !modalData?.sale_id,
    }
  );

  const singleSaleItemsList = singleSale?.data?.items;

  const submit = async (data) => {
    let response;

    try {
      if (isEditModal) {
        const editedSales = singleSaleItemsList.map((item) => {
          if (item.sale_item_id === Number(data.sale_item_id)) {
            return {
              ...item,
              quantity: Number(data.quantity),
            };
          }
          return item;
        });

        const editObj = {
          sale_id: modalData.sale_id,
          customer_id: data.customer_id,
          sale_date: data.sale_date,
          sub_total_amount: 200,
          total_amount: 250,
          discount_amount: 50,
          tax_amount: 100,
          items: editedSales,
        };

        response = await updateSales(editObj);
      } else {
        const obj = {
          customer_id: Number(data.customer_id),
          sale_date: data.sale_date,
          sub_total_amount: totals?.price || 0,
          total_amount: totals?.price || 0,
          discount_amount: totals?.discount || 0,
          tax_amount: totals?.tax_amount || 0,
          items: allProducts,
        };

        response = await addSale(obj);
      }

      if (response?.data?.success) {
        close();
        SWLAlert(response.data.message, "success");
      } else {
        SWLAlert(
          "Error",
          response?.data?.message || "Something went wrong",
          "error"
        );
      }
    } catch (error) {
      console.error("Add Sales error:", error);
      SWLAlert(
        "Error",
        "An error occurred while processing the request",
        "error"
      );
    }
  };

  useEffect(() => {
    if (salesModal?.modalData) {
      setValue("customer_id", salesModal?.modalData?.customer_id);
      setValue("sale_date", salesModal?.modalData?.sale_date);
    }
  }, [salesModal?.modalData]);

  const modalHeading = isViewModal
    ? "Sales Detail :"
    : isEditModal
    ? "Edit Sales"
    : "Add Sales";

  return (
    <>
      {" "}
      {isLoading || isSaleLoading || isUpdating ? <LoadingSpinner /> : null}
      <CustomModal
        heading={modalHeading}
        size="xl"
        onClose={close}
        isOpen={salesModal.modal}
      >
        {!isViewModal && (
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(submit)}>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>
                        <small className="text-danger">*</small>Customer Name
                      </label>
                      <div className="row">
                        <div className="col-lg-10 col-sm-10 col-10">
                          <Controller
                            name="customer_id"
                            control={control}
                            rules={{ required: "customer is required" }}
                            render={({ field }) => (
                              <Select
                                classNamePrefix="react-select"
                                options={customerDropdownData}
                                onChange={(e) => field.onChange(e.value)}
                                value={customerDropdownData.find(
                                  (option) => option.value === field.value
                                )}
                                isDisabled={
                                  salesModal?.modalData?.customer_id
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                          {errors.customer_id && (
                            <p className="text-danger">
                              {errors.customer_id.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-2 col-sm-2 col-2 ps-0">
                          <div className="add-icon">
                            <Link to="#" className="choose-add">
                              <PlusCircle className="plus" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>
                        <small className="text-danger">*</small>Date
                      </label>
                      <div className="input-groupicon calender-input">
                        <Controller
                          name="sale_date"
                          control={control}
                          render={({ field }) => (
                            <>
                              <Calendar className="info-img" />
                              <DatePicker
                                value={
                                  field?.value ? moment(field.value) : null
                                }
                                onChange={(date, dateString) =>
                                  field.onChange(dateString || null)
                                }
                                name={field.name}
                                className="filterdatepicker"
                                placeholder="Choose Date"
                                disabled={
                                  salesModal?.modalData?.sale_date
                                    ? true
                                    : false
                                }
                              />
                            </>
                          )}
                        />
                      </div>
                      {errors.sale_date && (
                        <p className="text-danger mt-1">
                          {errors.sale_date.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-sm-6 col-12">
                <div className="input-blocks">
                  <label>Supplier</label>
                  <Select
                    classNamePrefix="react-select"
                    options={suppliername}
                    placeholder="Newest"
                  />
                </div>
              </div> */}
                  {isAddModal && (
                    <div className="col-lg-12 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>Product Name</label>
                        <div className="input-groupicon select-code">
                          <Controller
                            control={control}
                            name="product"
                            rules={{
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                            }}
                            render={({ field }) => (
                              <Select
                                isSearchable
                                classNamePrefix="react-select"
                                options={productDropdown?.data || []}
                                onChange={(e) => {
                                  field.onChange(e.value),
                                    getSingleProduct(e.value);
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="table-responsive no-pagination">
                  <table className="table  datanew">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Price($)</th>
                        <th>Discount($)</th>
                        {/* <th>Tax(%)</th> */}
                        {/* <th>Tax Amount($)</th> */}
                        {/* <th>Unit Cost($)</th> */}
                        <th>Total Cost(%)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {!isViewModal && (
                      <tbody>
                        {allProducts?.map((pd) => (
                          <tr key={pd?.id}>
                            <td> {pd?.title || ""}</td>
                            <td> {pd?.qty || ""}</td>
                            <td> {pd?.price || ""}</td>
                            <td> {pd?.discount || ""}</td>
                            <td> {pd?.qty * pd?.price || ""}</td>
                            <td>
                              <div
                                className="me-3 confirm-text p-2 mb-0"
                                onClick={() => removeProductById(pd?.id)}
                              >
                                <i
                                  data-feather="trash-2"
                                  className="text-danger feather-trash-2"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                    {isEditModal ? (
                      singleSaleItemsList?.map((item) => (
                        <tbody key={item.sale_item_id}>
                          <tr>
                            <td>
                              <div className="productimgname">
                                <Link to="#" className="product-img stock-img">
                                  <ImageWithBasePath
                                    src="assets/img/products/stock-img-02.png"
                                    alt="product"
                                  />
                                </Link>
                                <Link to="#">Nike Jordan</Link>
                              </div>
                            </td>
                            <td>
                              <div className="product-quantity">
                                {/* <span
                                className="quantity-btn"
                                onClick={() => {
                                  increaseQtySingleSale(item.sale_item_id);
                                }}
                              >
                                +
                                <PlusCircle />
                              </span> */}
                                <input
                                  type="text"
                                  className="quntity-input"
                                  defaultValue={item.quantity}
                                  min={0}
                                  {...register("quantity")}
                                />
                                <input
                                  type="hidden"
                                  name="sale_item_id"
                                  value={item.sale_item_id}
                                  {...register("sale_item_id")}
                                />
                                {/* <span
                                className="quantity-btn"
                                onClick={() => {
                                  decreaseQtySingleSale(item.sale_item_id);
                                }}
                              >
                                <MinusCircle />
                              </span> */}
                              </div>
                            </td>
                            <td>{item.price}</td>
                            <td>{item.discount}</td>
                            <td>{item.quantity * item.price}</td>
                            <td>
                              <div className="me-3 confirm-text p-2 mb-0">
                                <i
                                  data-feather="trash-2"
                                  className="text-danger feather-trash-2"
                                  style={{ cursor: "pointer" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <></>
                    )}
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 ms-auto">
                    <div className="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>Order Tax</h4>
                          <h5>$ 0.00</h5>
                        </li>
                        <li>
                          <h4>Discount</h4>
                          {isAddModal ? (
                            <h5>$ {totals?.discount}</h5>
                          ) : (
                            <h5>$ {totals?.discount}</h5>
                          )}
                        </li>
                        <li>
                          <h4>Shipping</h4>
                          <h5>$ 0.00</h5>
                        </li>
                        <li>
                          <h4>Grand Total</h4>
                          {isAddModal ? (
                            <h5>$ {totals?.price}</h5>
                          ) : (
                            <h5>$ {totals?.price}</h5>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-3 col-sm-6 col-12">
                <div className="input-blocks">
                  <label>Order Tax</label>
                  <div className="input-groupicon select-code">
                    <input type="text" defaultValue={0} className="p-2" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="input-blocks">
                  <label>Discount</label>
                  <div className="input-groupicon select-code">
                    <input type="text" defaultValue={0} className="p-2" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="input-blocks">
                  <label>Shipping</label>
                  <div className="input-groupicon select-code">
                    <input type="text" defaultValue={0} className="p-2" />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="input-blocks mb-5">
                  <label>Status</label>
                  <Select
                    classNamePrefix="react-select"
                    // options={statusupdate || []}
                    placeholder="status"
                  />
                </div>
              </div> */}
                  <div className="col-lg-12 text-end">
                    <button
                      type="button"
                      className="btn btn-cancel add-cancel me-3"
                      // data-bs-dismiss="modal"
                      onClick={close}
                    >
                      Cancel
                    </button>
                    {isLoading || isUpdating ? (
                      <button
                        className="btn btn-primary-light"
                        type="button"
                        disabled=""
                      >
                        <span
                          className="spinner-border spinner-border-sm align-middle me-1"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-submit add-sale"
                        disabled={
                          allProducts.length === 0 &&
                          salesModal?.modalType === "Add"
                        }
                      >
                        {salesModal?.modalType === "Add" ? "save" : "Update"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* View Sales Modal */}
        {isViewModal && (
          <>
            <div className="page-wrapper details-blk">
              <div className="content p-0">
                <div className="page-header p-4 mb-0">
                  <div className="add-item d-flex">
                    {/* <div className="page-title modal-datail">
                  <h4>Sales Detail : SL0101</h4>
                </div> */}
                  </div>
                  <ul className="table-top-head">
                    <li>
                      <Link
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Pdf"
                      >
                        <i
                          data-feather="edit"
                          className="feather-edit sales-action"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Pdf"
                      >
                        <ImageWithBasePath
                          src="assets/img/icons/pdf.svg"
                          alt="img"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Excel"
                      >
                        <ImageWithBasePath
                          src="assets/img/icons/excel.svg"
                          alt="img"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Print"
                      >
                        <i
                          data-feather="printer"
                          className="feather-rotate-ccw"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="invoice-box table-height">
                        <div className="sales-details-items d-flex">
                          <div className="details-item">
                            <h6>Customer Info</h6>
                            <p>{singleSale?.data?.customer_name || ""}</p>
                          </div>
                          <div className="details-item">
                            <h6>Company Info</h6>
                            <p></p>
                          </div>
                          <div className="details-item">
                            <h6>Invoice Info</h6>
                            <p>
                              {formatDate(singleSale?.data?.sale_date) || ""}
                            </p>
                          </div>
                          <div className="details-item">
                            <h5>
                              {/* <span></span>Paid */}
                              <br /> {singleSale?.data?.payment_status || ""}
                            </h5>
                          </div>
                        </div>
                        {/* <h5 className="order-text">Order Summary</h5> */}
                        <div className="table-responsive no-pagination">
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Qty</th>
                                <th>Purchase Price($)</th>
                                <th>Discount($)</th>
                                <th>Tax(%)</th>
                                <th>Tax Amount($)</th>
                                <th>Unit Cost($)</th>
                                <th>Total Cost(%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {singleSaleItemsList?.map((item) => (
                                <tr key={item.sale_item_id}>
                                  <td>
                                    <div className="productimgname">
                                      <Link
                                        to="#"
                                        className="product-img stock-img"
                                      >
                                        <ImageWithBasePath
                                          src="assets/img/products/stock-img-02.png"
                                          alt="product"
                                        />
                                      </Link>
                                      <Link to="#">Nike Jordan</Link>
                                    </div>
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>{item.price}</td>
                                  <td>{item.discount}</td>
                                  <td>0.00</td>
                                  <td>{item.tax_amount}</td>
                                  <td>0.00</td>
                                  <td>{item.total}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row">
                          <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                              <ul>
                                <li>
                                  <h4>Order Tax</h4>
                                  <h5>$ {singleSale?.data?.tax_amount || 0}</h5>
                                </li>
                                <li>
                                  <h4>Discount</h4>
                                  <h5>
                                    $ {singleSale?.data?.discount_amount || 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Grand Total</h4>
                                  <h5>
                                    $ {singleSale?.data?.total_amount || 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Paid</h4>
                                  <h5>
                                    $ {singleSale?.data?.paid_amount || 0}
                                  </h5>
                                </li>
                                <li>
                                  <h4>Due</h4>
                                  <h5>
                                    ${" "}
                                    {singleSale?.data?.total_amount -
                                      singleSale?.data?.paid_amount || 0}
                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </CustomModal>
    </>
  );
};

export default AddSalesModal;

const addSalesScehma = yup.object().shape({
  customer_id: yup.string().required("customer is required"),
  sale_date: yup.string().required("date is required"),
});
