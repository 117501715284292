// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../core/redux/baseQuery";

// Define a service using a base URL and expected endpoints
export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: baseQuery,
  tagTypes: ["payment"],
  endpoints: (builder) => ({
    getPaymentList: builder.query({
      query: (id) => ({
        url: `payment/?sale_id=${id}`,
        method: "GET",
      }),
      providesTags: ["payment"],
    }),
    getPayment: builder.query({
      query: (id) => ({
        url: `payment/${id}`,
        method: "GET",
      }),
      providesTags: ["payment"],
    }),
    addPayment: builder.mutation({
      query: (data) => ({
        url: "payment",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["payment"],
    }),
    updatePayment: builder.mutation({
      query: (data) => ({
        url: "payment",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["payment"],
    }),
    deletePayment: builder.mutation({
      query: (id) => ({
        url: `payment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["payment"],
    }),
  }),
});

export const {
  useGetPaymentListQuery,
  useGetPaymentQuery,
  useAddPaymentMutation,
  useUpdatePaymentMutation,
  useDeletePaymentMutation,
} = paymentApi;

export default paymentApi;
