import React from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { useSingleProductQuery } from "../../core/redux/products.api";
import { get_storage_item } from "../../utils/Method";
import { useSelector } from "react-redux";
import { discounttype, taxtype } from "./addproduct";
import LoadingSpinner from "../../InitialPage/Sidebar/LoadingSpinner";
import { Link } from "react-router-dom";
import { ArrowLeft } from "feather-icons-react/build/IconComponents";

const ProductDetail = () => {
  const { selectedProduct } = useSelector((state) => state.productsReducer);
  const {
    data: objData,
    isFetching,
    isLoading,
  } = useSingleProductQuery(
    get_storage_item("productId") || selectedProduct?.id
      ? get_storage_item("productId") || selectedProduct?.id
      : skipToken
  );
  const data = objData?.data || {};
  return (
    <div>
      {isLoading || isFetching ? <LoadingSpinner /> : null}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>Product Details</h4>
              <h6>Full details of a product</h6>
            </div>
            <div className="page-btn">
              <Link to={"/product-list"} className="btn btn-secondary">
              <ArrowLeft className="me-2" />
                Back to Product
              </Link>
            </div>
          </div>
          {/* /add */}
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  {/* <div className="bar-code-view">
                        <ImageWithBasePath
                          src="assets/img/barcode/barcode1.png"
                          alt="barcode"
                        />
                        <a className="printimg">
                          <ImageWithBasePath
                            src="assets/img/icons/printer.svg"
                            alt="print"
                          />
                        </a>
                      </div> */}
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Product</h4>
                        <h6>{data?.title} </h6>
                      </li>
                      <li>
                        <h4>Category</h4>
                        <h6>{data?.category_title ?? "-"}</h6>
                      </li>
                      {/* <li>
                            <h4>Sub Category</h4>
                            <h6>None</h6>
                          </li> */}
                      {/* <li>
                            <h4>Brand</h4>
                            <h6>None</h6>
                          </li> */}
                      <li>
                        <h4>Unit</h4>
                        <h6>{data?.unit_title}</h6>
                      </li>
                      {/* <li>
                            <h4>SKU</h4>
                            <h6>-</h6>
                          </li> */}
                      <li>
                        <h4>Minimum Qty</h4>
                        <h6>{data?.qty_alert}</h6>
                      </li>
                      <li>
                        <h4>Quantity</h4>
                        <h6>{data?.qty}</h6>
                      </li>
                      {/* <li>
                        <h4>Tax</h4>
                        <h6>
                          {isLoading || isFetching
                            ? null
                            : Number(data?.tax_type ?? 0) === 1
                            ? "Exclusive"
                            : "Sales Tax"}
                        </h6>
                      </li> */}
                      <li>
                        <h4>Discount Type</h4>
                        <h6>
                          {isLoading || isFetching
                            ? null
                            : Number(data?.discount_type ?? 0) === 1
                            ? "Percentage"
                            : "Cash"}
                        </h6>
                      </li>
                      <li>
                        <h4>Discount</h4>
                        <h6>{data?.discount ?? "-"}</h6>
                      </li>
                      <li>
                        <h4>Price</h4>
                        <h6>{data?.price}</h6>
                      </li>
                      <li>
                        <h4>Status</h4>
                        <h6
                          style={{
                            color: data?.status ? "green" : "red",
                          }}
                        >
                          {isLoading || isFetching
                            ? null
                            : data?.status
                            ? "Active"
                            : "In-Active"}
                        </h6>
                      </li>
                      <li>
                        <h4>Description</h4>
                        <h6>{data?.description ?? "-"}</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
           {/*  <div className="col-lg-4 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="slider-product-details">
                    <div className="owl-carousel owl-theme product-slide">
                      <div className="slider-product">
                        <ImageWithBasePath
                          src="assets/img/products/product69.jpg"
                          alt="img"
                        />
                        <h4>macbookpro.jpg</h4>
                        <h6>581kb</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
