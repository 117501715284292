export const categorylist = [
  {
    id: 1,
    category: "Appetizers",
    categoryslug: "appetizers",
    createdon: "05 Jan 2023",
    status: "Active",
    description: "Small dishes served before the main course.",
    parentCategory: "Food",
    lastUpdated: "10 Jan 2023",
  },
  {
    id: 2,
    category: "Main Courses",
    categoryslug: "main-courses",
    createdon: "12 Jan 2023",
    status: "Active",
    description: "The primary dishes in a meal.",
    parentCategory: "Food",
    lastUpdated: "20 Jan 2023",
  },
  {
    id: 3,
    category: "Desserts",
    categoryslug: "desserts",
    createdon: "20 Feb 2023",
    status: "Active",
    description: "Sweet courses served at the end of a meal.",
    parentCategory: "Food",
    lastUpdated: "25 Feb 2023",
  },
  {
    id: 4,
    category: "Beverages",
    categoryslug: "beverages",
    createdon: "15 Mar 2023",
    status: "Active",
    description: "Drinks, both alcoholic and non-alcoholic.",
    parentCategory: "Drinks",
    lastUpdated: "18 Mar 2023",
  },
  {
    id: 5,
    category: "Cocktails",
    categoryslug: "cocktails",
    createdon: "25 Apr 2023",
    status: "Active",
    description: "Mixed drinks usually containing alcohol.",
    parentCategory: "Drinks",
    lastUpdated: "30 Apr 2023",
  },
  {
    id: 6,
    category: "Wines",
    categoryslug: "wines",
    createdon: "10 May 2023",
    status: "Active",
    description: "Various types of wines.",
    parentCategory: "Drinks",
    lastUpdated: "15 May 2023",
  },
  {
    id: 7,
    category: "Beers",
    categoryslug: "beers",
    createdon: "01 Jun 2023",
    status: "Active",
    description: "Different varieties of beers.",
    parentCategory: "Drinks",
    lastUpdated: "05 Jun 2023",
  },
  {
    id: 8,
    category: "Rooms",
    categoryslug: "rooms",
    createdon: "20 Jun 2023",
    status: "Active",
    description: "Different types of hotel rooms.",
    parentCategory: "Accommodation",
    lastUpdated: "25 Jun 2023",
  },
  {
    id: 9,
    category: "Suites",
    categoryslug: "suites",
    createdon: "30 Jun 2023",
    status: "Active",
    description: "Luxury hotel rooms with additional amenities.",
    parentCategory: "Accommodation",
    lastUpdated: "05 Jul 2023",
  },
  {
    id: 10,
    category: "Event Spaces",
    categoryslug: "event-spaces",
    createdon: "10 Jul 2023",
    status: "Active",
    description: "Spaces for hosting events and conferences.",
    parentCategory: "Facilities",
    lastUpdated: "15 Jul 2023",
  },
  {
    id: 11,
    category: "Spa Services",
    categoryslug: "spa-services",
    createdon: "20 Jul 2023",
    status: "Active",
    description: "Wellness and relaxation services.",
    parentCategory: "Facilities",
    lastUpdated: "25 Jul 2023",
  },
  {
    id: 12,
    category: "Gym",
    categoryslug: "gym",
    createdon: "01 Aug 2023",
    status: "Active",
    description: "Fitness facilities available for guests.",
    parentCategory: "Facilities",
    lastUpdated: "05 Aug 2023",
  },
  {
    id: 13,
    category: "Housekeeping",
    categoryslug: "housekeeping",
    createdon: "15 Aug 2023",
    status: "Active",
    description: "Services for maintaining cleanliness and order.",
    parentCategory: "Services",
    lastUpdated: "20 Aug 2023",
  },
  {
    id: 14,
    category: "Room Service",
    categoryslug: "room-service",
    createdon: "01 Sep 2023",
    status: "Active",
    description: "Food and beverages delivered to guest rooms.",
    parentCategory: "Services",
    lastUpdated: "05 Sep 2023",
  },
  {
    id: 15,
    category: "Concierge",
    categoryslug: "concierge",
    createdon: "10 Sep 2023",
    status: "Active",
    description: "Guest assistance and services.",
    parentCategory: "Services",
    lastUpdated: "15 Sep 2023",
  },
  {
    id: 16,
    category: "Meetings & Events",
    categoryslug: "meetings-events",
    createdon: "20 Sep 2023",
    status: "Active",
    description: "Organized meetings and events.",
    parentCategory: "Facilities",
    lastUpdated: "25 Sep 2023",
  },
];
