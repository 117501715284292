export const expiredproduct = [
  {
    id: 1,
    img: "assets/img/products/milk-carton.png",
    product: "Organic Whole Milk",
    sku: "DR005",
    manufactureddate: "15 Feb 2023",
    expireddate: "15 Mar 2023",
  },
  {
    id: 2,
    img: "assets/img/products/lettuce.png",
    product: "Romaine Lettuce",
    sku: "FD006",
    manufactureddate: "01 Mar 2023",
    expireddate: "10 Mar 2023",
  },
  {
    id: 3,
    img: "assets/img/products/sliced-bread.png",
    product: "Sliced Bread",
    sku: "FD007",
    manufactureddate: "20 Jan 2023",
    expireddate: "30 Jan 2023",
  },
  {
    id: 4,
    img: "assets/img/products/yogurt.png",
    product: "Greek Yogurt",
    sku: "DR006",
    manufactureddate: "05 Apr 2023",
    expireddate: "20 Apr 2023",
  },
  {
    id: 5,
    img: "assets/img/products/cheese.png",
    product: "Cheddar Cheese",
    sku: "FD008",
    manufactureddate: "18 Feb 2023",
    expireddate: "18 May 2023",
  },
  {
    id: 6,
    img: "assets/img/products/beer-can.png",
    product: "Craft Beer - IPA",
    sku: "DR007",
    manufactureddate: "10 Mar 2023",
    expireddate: "10 Jun 2023",
  },
  {
    id: 7,
    img: "assets/img/products/salmon.png",
    product: "Smoked Salmon",
    sku: "FD009",
    manufactureddate: "15 Feb 2023",
    expireddate: "15 Mar 2023",
  },
  {
    id: 8,
    img: "assets/img/products/ham.png",
    product: "Deli Ham",
    sku: "FD010",
    manufactureddate: "20 Feb 2023",
    expireddate: "20 Mar 2023",
  },
  {
    id: 9,
    img: "assets/img/products/bottled-water.png",
    product: "Bottled Water",
    sku: "DR008",
    manufactureddate: "01 Jan 2023",
    expireddate: "01 Jan 2024",
  },
  {
    id: 10,
    img: "assets/img/products/frozen-pizza.png",
    product: "Frozen Pepperoni Pizza",
    sku: "FD011",
    manufactureddate: "10 Nov 2022",
    expireddate: "10 May 2023",
  },
];
