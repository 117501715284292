import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stockId: null,
  warehouseId: null,
  productId: null,
  productQty: null,
  addModalToggle: false,
  editModalToggle: false,
  stocks: false,
  selectedStock: {},
};

const stocksApi = createSlice({
  name: "stockReducer",
  initialState,
  reducers: {
    setOpenStock: (state, action) => {
      state.stocks = action.payload;
    },
    setSelectedStock: (state, action) => {
      state.selectedStock = action.payload;
    },
    setAddStockModal: (state, action) => {
      state.addModalToggle = action.payload;
    },
    setEditStockModal: (state, action) => {
      state.editModalToggle = action.payload;
    },
    setStockId: (state, action) => {
      state.stockId = action.payload;
    },
    setWarehouseId: (state, action) => {
      state.warehouseId = action.payload;
    },
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setProductQty: (state, action) => {
      state.productQty = action.payload;
    },
  },
});

export const {
  setOpenStock,
  setSelectedStock,
  setAddStockModal,
  setEditStockModal,
  setStockId,
  setWarehouseId,
  setProductId,
  setProductQty,
} = stocksApi.actions;
export const stockReducer = stocksApi.reducer;
