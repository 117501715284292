export const unitsdata = [
  {
    id: 1,
    unit: "Bottle",
    shortname: "BTL",
    noofproducts: "50",
    createdon: "10 Jan 2023",
    status: "Active",
  },
  {
    id: 2,
    unit: "Glass",
    shortname: "GLS",
    noofproducts: "120",
    createdon: "20 Feb 2023",
    status: "Active",
  },
  {
    id: 3,
    unit: "Plate",
    shortname: "PLT",
    noofproducts: "200",
    createdon: "05 Mar 2023",
    status: "Active",
  },
  {
    id: 4,
    unit: "Can",
    shortname: "CAN",
    noofproducts: "75",
    createdon: "18 Apr 2023",
    status: "Active",
  },
  {
    id: 5,
    unit: "Package",
    shortname: "PKG",
    noofproducts: "30",
    createdon: "25 May 2023",
    status: "Active",
  },
  {
    id: 6,
    unit: "Serving",
    shortname: "SRV",
    noofproducts: "150",
    createdon: "10 Jun 2023",
    status: "Active",
  },
  {
    id: 7,
    unit: "Slice",
    shortname: "SLC",
    noofproducts: "100",
    createdon: "15 Jul 2023",
    status: "Active",
  },
  {
    id: 8,
    unit: "Cup",
    shortname: "CUP",
    noofproducts: "90",
    createdon: "20 Aug 2023",
    status: "Active",
  },
  {
    id: 9,
    unit: "Pint",
    shortname: "PNT",
    noofproducts: "80",
    createdon: "30 Sep 2023",
    status: "Active",
  },
  {
    id: 10,
    unit: "Quart",
    shortname: "QRT",
    noofproducts: "40",
    createdon: "10 Oct 2023",
    status: "Active",
  },
];
