export const payrollListData = [
  {
    id: "1",
    name: "Mitchum Daniel",
    role: "Database Administrator",
    id2: "POS001",
    email: "mir34345@example.com",
    salary: "$30,000",
    status: "Paid",
    image: "assets/img/users/user-01.jpg",
  },
  {
    id: "2",
    name: "Susan Lopez",
    role: "Curator",
    id2: "POS002",
    email: "susanopez@example.com",
    salary: "$20,000",
    status: "Paid",
    image: "assets/img/users/user-02.jpg",
  },
  {
    id: "3",
    name: "Robert",
    role: "System Administrator",
    id2: "POS003",
    email: "robertgman@example.com",
    salary: "$25,000",
    status: "UnPaid",
    image: "assets/img/users/user-03.jpg",
  },
  {
    id: "4",
    name: "Janet Hembre",
    role: "Administrative Officer",
    id2: "POS004",
    email: "janetembre@example.com",
    salary: "$23,000",
    status: "Paid",
    image: "assets/img/users/user-06.jpg",
  },
  {
    id: "5",
    name: "Russell Belle",
    role: "Technician",
    id2: "POS005",
    email: "russellbelle@example.com",
    salary: "$35,000",
    status: "Paid",
    image: "assets/img/users/user-04.jpg",
  },
  {
    id: "6",
    name: "Edward Muniz",
    role: "Office Support Secretary",
    id2: "POS006",
    email: "edward@example.com",
    salary: "$28,000",
    status: "UnPaid",
    image: "assets/img/users/user-05.jpg",
  },
  {
    id: "7",
    name: "Susan Moore",
    role: "Tech Lead",
    id2: "POS007",
    email: "susanmoore@example.com",
    salary: "$27,000",
    status: "Paid",
    image: "assets/img/users/user-07.jpg",
  },
];
