export const SupplierData = [
  {
    id: "1",
    supplierName: "Wine Wholesalers Inc.",
    code: "101",
    email: "info@winewholesalers.com",
    phone: "+12165551234",
    country: "USA",
    image: "assets/img/supplier/wine-wholesalers.png"
  },
  {
    id: "2",
    supplierName: "Beverage Distributors Ltd.",
    code: "102",
    email: "info@beveragedistributors.com",
    phone: "+441234567890",
    country: "UK",
    image: "assets/img/supplier/beverage-distributors.png"
  },
  {
    id: "3",
    supplierName: "Coffee Roasters Inc.",
    code: "103",
    email: "info@coffeeroasters.com",
    phone: "+6112345678",
    country: "Australia",
    image: "assets/img/supplier/coffee-roasters.png"
  },
  {
    id: "4",
    supplierName: "Kitchen Essentials Ltd.",
    code: "104",
    email: "info@kitchenessentials.com",
    phone: "+8123456789",
    country: "Japan",
    image: "assets/img/supplier/kitchen-essentials.png"
  },
  {
    id: "5",
    supplierName: "Frozen Foods Inc.",
    code: "105",
    email: "info@frozenfoods.com",
    phone: "+27123456789",
    country: "South Africa",
    image: "assets/img/supplier/frozen-foods.png"
  },
];
