export const productlistdata = [
  {
    id: 1,
    product: "Classic Margarita",
    productImage: "assets/img/products/margarita.png",
    sku: "DR001",
    category: "Cocktails",
    brand: "Signature Cocktails",
    price: "$8.50",
    unit: "Glass",
    qty: "200",
    createdby: "John Doe",
    img: "assets/img/users/user-01.jpg",
  },
  {
    id: 2,
    product: "Caesar Salad",
    productImage: "assets/img/products/caesar-salad.png",
    sku: "FD001",
    category: "Appetizers",
    brand: "Healthy Eats",
    price: "$12.00",
    unit: "Plate",
    qty: "150",
    createdby: "Jane Smith",
    img: "assets/img/users/user-02.jpg",
  },
  {
    id: 3,
    product: "Grilled Steak",
    productImage: "assets/img/products/grilled-steak.png",
    sku: "FD002",
    category: "Main Courses",
    brand: "Prime Cuts",
    price: "$25.00",
    unit: "Serving",
    qty: "100",
    createdby: "Michael Brown",
    img: "assets/img/users/user-03.jpg",
  },
  {
    id: 4,
    product: "Chocolate Lava Cake",
    productImage: "assets/img/products/lava-cake.png",
    sku: "DS001",
    category: "Desserts",
    brand: "Sweet Tooth",
    price: "$10.00",
    unit: "Piece",
    qty: "120",
    createdby: "Emily White",
    img: "assets/img/users/user-04.jpg",
  },
  {
    id: 5,
    product: "Red Wine - Merlot",
    productImage: "assets/img/products/red-wine.png",
    sku: "DR002",
    category: "Wines",
    brand: "Fine Vines",
    price: "$15.00",
    unit: "Bottle",
    qty: "80",
    createdby: "David Johnson",
    img: "assets/img/users/user-05.jpg",
  },
  {
    id: 6,
    product: "Espresso Coffee",
    productImage: "assets/img/products/espresso.png",
    sku: "DR003",
    category: "Beverages",
    brand: "Coffee Masters",
    price: "$3.00",
    unit: "Cup",
    qty: "300",
    createdby: "Laura Wilson",
    img: "assets/img/users/user-06.jpg",
  },
  {
    id: 7,
    product: "Club Sandwich",
    productImage: "assets/img/products/club-sandwich.png",
    sku: "FD003",
    category: "Main Courses",
    brand: "Sandwich Heaven",
    price: "$10.50",
    unit: "Piece",
    qty: "200",
    createdby: "James Taylor",
    img: "assets/img/users/user-07.jpg",
  },
  {
    id: 8,
    product: "Orange Juice",
    productImage: "assets/img/products/orange-juice.png",
    sku: "DR004",
    category: "Beverages",
    brand: "Tropicana",
    price: "$4.00",
    unit: "Glass",
    qty: "150",
    createdby: "Patricia Brown",
    img: "assets/img/users/user-08.jpg",
  },
  {
    id: 9,
    product: "Chicken Wings",
    productImage: "assets/img/products/chicken-wings.png",
    sku: "FD004",
    category: "Appetizers",
    brand: "Wing Masters",
    price: "$15.00",
    unit: "Plate",
    qty: "180",
    createdby: "Christopher Lee",
    img: "assets/img/users/user-09.jpg",
  },
  {
    id: 10,
    product: "Pepperoni Pizza",
    productImage: "assets/img/products/pepperoni-pizza.png",
    sku: "FD005",
    category: "Main Courses",
    brand: "Pizza Palace",
    price: "$18.00",
    unit: "Slice",
    qty: "250",
    createdby: "Elizabeth Davis",
    img: "assets/img/users/user-10.jpg",
  },
];
