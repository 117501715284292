export const deleteaccountdata = [
  {
    id: 1,
    img: "assets/img/users/user-01.jpg",
    username: "Steven",
    requisitiondate: "25 Sep 2023",
    deleterequisitiondate: "01 Oct 2023",
  },
  {
    id: 2,
    img: "assets/img/users/user-02.jpg",
    username: "Susan Lopez",
    requisitiondate: "30 Sep 2023",
    deleterequisitiondate: "05 Oct 2023",
  },
  {
    id: 3,
    img: "assets/img/users/user-03.jpg",
    username: "Robert Grossman",
    requisitiondate: "10 Sep 2023",
    deleterequisitiondate: "25 Sep 2023",
  },
  {
    id: 4,
    img: "assets/img/users/user-04.jpg",

    username: "Janet Hembre",
    requisitiondate: "15 Sep 2023",
    deleterequisitiondate: "20 Sep 2023",
  },
  {
    id: 5,
    img: "assets/img/users/user-05.jpg",
    username: "Russell Belle",
    requisitiondate: "15 Aug 2023",
    deleterequisitiondate: "01 Sep 2023",
  },
];
