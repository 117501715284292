import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useController, useForm, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAddUnitMutation } from "../../redux/unitsapi";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setOpenUnit, setSelectedUnit } from "../../redux/units.slice";
import GrowLoader from "../../../feature-module/uiinterface/GrowLoader";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";

const AddUnit = (props) => {
  const { units, selectedUnit } = useSelector((state) => state?.unitReducer);
  const { handleSubmit, watch, register, getValues, reset, setValue } =
    useFormContext();
  const [addUnit, { isLoading }] = useAddUnitMutation();
  const submit = (props) => {
    addUnit({ ...props, status: props?.status ? 1 : 0 }).then((res) => {
      if (res?.data?.success) {
        dispatch(setOpenUnit(false));
        dispatch(setSelectedUnit({}));
        SWLAlert("Success", res?.data?.message, "success");
      } else {
        SWLAlert("Error", res?.data?.message, "error");
      }
    });
  };
  const values = getValues();
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenUnit(false));
  };
  useEffect(() => {
    if (selectedUnit?.id) {
      setValue("title", selectedUnit?.title);
      setValue("status", selectedUnit?.status);
      setValue("short_name", selectedUnit?.short_name);
      setValue("id", selectedUnit?.id);
    }
  }, [selectedUnit, setValue]);
  return (
    <>
      {isLoading ? <GrowLoader /> : null}
      <CustomModal
        isOpen={units}
        heading={selectedUnit?.id ? "Update Unit" : "Create Unit"}
        onClose={close}
      >
        <form noValidate onSubmit={handleSubmit(submit)} id="unit-form">
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              {...register("title")}
              defaultValue={watch("title")}
              onChange={(e) => {
                setValue("title", e?.target?.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Short Name</label>
            <input
              type="text"
              className="form-control"
              {...register("short_name")}
              defaultValue={watch("short_name")}
            />
          </div>
          {selectedUnit?.id ? (
            <div className="mb-0">
              <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                <span className="status-label">Status</span>
                <input
                  type="checkbox"
                  id="user2"
                  className="check"
                  defaultChecked="true"
                  {...register("status")}
                  value={watch("status")}
                />
                <label htmlFor="user2" className="checktoggle" />
              </div>
            </div>
          ) : null}
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="btn btn-submit"
              type="submit"
              disabled={values?.short_name === "" || values?.title === ""}
            >
              {selectedUnit?.id ? "Update Unit" : "Create Unit"}
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default AddUnit;
