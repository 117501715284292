import Select from "react-select";
import React from "react";

const CustomDropdown = ({
  name,
  value,
  placeholder = "Chose",
  label,
  options = [],
  onChange,
  ...rest
}) => {
  const values = [...(options || [])]?.find((ops) => ops?.value === value);
  return (
    <>
      {label ? <label className="form-label">{label}</label> : null}
      <Select
        classNamePrefix="react-select"
        options={options}
        placeholder={placeholder}
        isClearable
        value={values}
        onChange={onChange}
        {...rest}
      />
    </>
  );
};

export default CustomDropdown;
