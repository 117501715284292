import { createSlice } from '@reduxjs/toolkit';

const initialState= {
  roles: false,
  selectedUnit:{}
};

const rolesApi = createSlice({
  name: 'roleReducer',
  initialState,
  reducers: {
    setOpenRole: (state, action) => {
      state.roles = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },

  },
});

export const {
setOpenRole,
setSelectedRole
} = rolesApi.actions;
export const roleReducer = rolesApi.reducer;
