import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useGetAllRoleQuery, useGetDropdownRoleQuery } from "../../../services/role.api";
import { useAddUserMutation } from "../../../services/user.api";
import {
  setOpenUser,
  setSelectedUser,
} from "../../redux/slices/user.slice";
import { SWLAlert } from "../../../feature-module/uiinterface/SWLAlert";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../feature-module/uiinterface/CustomModal";

const AddUsers = () => {

  const { users, selectedUser } = useSelector(
    (state) => state?.userReducer
  );

  const [addUser, { isLoading }] = useAddUserMutation();

  const { data: rolesOptions } = useGetDropdownRoleQuery();

  const genderOptions = [
    { value: "", label: "Choose" },
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onSubmit = async (props) => {
    try {
      const res = await addUser({
        ...props,
        status: props?.status ? 1 : 0,
      }).unwrap();
      if (res?.success == 1) {
        dispatch(setOpenUser(false));
        dispatch(setSelectedUser({}));
        SWLAlert("Success", res?.message, "success");
        reset();
      } else {
        SWLAlert("Error", res?.message, "error");
      }
    } catch (error) {
      SWLAlert("Error", "There was an error adding the User.", "error");
    }
  };

  const dispatch = useDispatch();
  const close = () => {
    dispatch(setOpenUser(false));
    reset();
  };

  useEffect(() => {
    if (selectedUser?.id) {
      setValue("id", selectedUser?.id);
      setValue("name", selectedUser?.name);
      setValue("email", selectedUser?.email);
      setValue("phone", selectedUser?.phone);
      setValue("gender", selectedUser?.gender);
      setValue("roleId", selectedUser?.role_id);
      setValue("status", selectedUser?.status);
    }
  }, [selectedUser?.id, selectedUser]);

  const heading = selectedUser?.id
    ? "Update User"
    : "Add User";



  const [showPassword, setShowPassword] = useState(false);


  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const modal_ref = useRef(null);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const handleToggleConfirmPassword = () => {
    setConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const OnHandleInputChange = (value, field) => {
    const currentValues = getValues();
    setValue(field, value);
    setValue("formData", { ...currentValues, [field]: value });
  };


  return (
    <div>
      {/* Add User */}
      <CustomModal heading={heading} onClose={close} isOpen={users}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <label> <small className="text-danger">*</small>Name</label>
                <input
                  type="text"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  className="form-control"
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Phone</label>
                <input
                  type="text"
                  {...register("phone", {
                    required: "Phone is required",
                  })}
                  className="form-control"
                />
                {errors.phone && (
                  <p className="text-danger">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label><small className="text-danger">*</small>Email</label>
                <input
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      message: "Email is not valid",
                    },
                  })}
                  type="email"
                  className="form-control"
                />
                {errors.email && <p className="text-danger">{errors.email.message}</p>}
              </div>
            </div>
            <div className="col-lg-6 pe-0">
              <div className="mb-3">
                <label className="form-label">Role</label>
                <Controller
                  name="roleId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("roleId", {
                        required: "Role is required",
                      })}
                      classNamePrefix="react-select"
                      options={rolesOptions?.data || []}
                      onChange={(e) => field.onChange(e.value)}
                      value={
                        rolesOptions?.data
                          ? rolesOptions?.data.find(
                            (option) => option.value === field.value
                          )
                          : ""
                      }
                    />
                  )}
                />
                {errors.roleId && (
                  <p className="text-danger">{errors.roleId.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 pe-0">
              <div className="mb-3">
                <label className="form-label">Gender</label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                      classNamePrefix="react-select"
                      options={genderOptions || []}
                      onChange={(e) => field.onChange(e.value)}
                      value={
                        genderOptions
                          ? genderOptions.find(
                            (option) => option.value == field.value
                          )
                          : ""
                      }
                    />
                  )}
                />
                {errors.gender && (
                  <p className="text-danger">{errors.gender.message}</p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Password</label>
                <div className="pass-group">
                  <input
                    {...register("password", {
                      required: "Password is required",
                    })}
                    type={showPassword ? "text" : "password"}
                    className="pass-input"
                    placeholder="Enter your password"
                  /* onChange={(val) =>
                    OnHandleInputChange(
                      val.target.value,
                      "password"
                    )
                  } */
                  />
                  {errors.password && (
                    <p className="text-danger">{errors.password.message}</p>
                  )}
                  <span
                    className={`fas toggle-password ${showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    onClick={handleTogglePassword}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Confirm Password</label>
                <div className="pass-group">
                  <input
                    {...register("confirm_password", {
                      required: "Confirm Password is required",
                    })}
                    type={showConfirmPassword ? "text" : "password"}
                    className="pass-input"
                    placeholder="Enter your password"
                  /* onChange={(val) =>
                    OnHandleInputChange(
                      val.target.value,
                      "confirm_password"
                    )
                  } */
                  />
                  {errors.confirm_password && (
                    <p className="text-danger">{errors.confirm_password.message}</p>
                  )}
                  <span
                    className={`fas toggle-password ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                    onClick={handleToggleConfirmPassword}
                  />
                </div>
              </div>
            </div>
            {selectedUser?.id ? (
                <div className="mb-0">
                  <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                    <span className="status-label">Status</span>
                    <input
                      type="checkbox"
                      id="user2"
                      className="check"
                      defaultChecked="true"
                      {...register("status")}
                    />
                    <label htmlFor="user2" className="checktoggle" />
                  </div>
                </div>
              ) : null}
          </div>
          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={close}
            >
              Cancel
            </button>
            {isLoading ? (
              <button
                className="btn btn-primary-light"
                type="button"
                disabled=""
              >
                <span
                  className="spinner-border spinner-border-sm align-middle me-1"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-submit"
                disabled={isLoading}
              >
                {selectedUser?.id ? 'Update' : 'Save'}
              </button>
            )}
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default AddUsers;
